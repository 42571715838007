import React, { useState, useEffect } from 'react';

const Snackbar = ({ message, type, showSnackbar, setShowSnackbar }) => {
  const [isVisible, setIsVisible] = useState(showSnackbar);
  const backgroundColorClass = type === 'success' ? 'bg-green-100 border-green-400 text-green-700' : 'bg-red-100 border-red-400 text-red-700';

  useEffect(() => {
    setIsVisible(showSnackbar);
  }, [showSnackbar]);

  const handleClose = () => {
    setIsVisible(false);
    setShowSnackbar(false);
  };

  return (
    <>
      {isVisible && (
        <div className={`opacity-100 translate-y-0 fixed top-4 left-1/2 transform -translate-x-1/2 w-full border px-4 py-3 rounded-md relative transition-all duration-300 ${backgroundColorClass}`}>
          <span className="block sm:inline text-sm">{message}</span>
          <span className="absolute top-4 right-2 cursor-pointer" onClick={handleClose}>
            <svg className="fill-current h-4 w-4 text-current" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      )}
    </>
  );
};

export default Snackbar;
