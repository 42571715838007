export const validateFields = (formData) => {
    const errors = {};

    if (!formData.email) {
        errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        errors.email = "Email is invalid";
    } else if (formData.email.length > 256) {
        errors.email = `The email must be 256 characters or less`;
    }

    if (!formData.password) {
        errors.password = "Password is required";
    } else if (formData.password.length < 6) {
        errors.password = "The password must be 6 characters or more";
    } else if (formData.password.length > 32) {
        errors.password = `The password must be 32 characters or less`;
    }

    // if (!formData.first_name.trim()) {
    //     errors.first_name = "First name is required"
    // } else if(!/^[a-zA-Z- ]+$/.test(formData.first_name)){
    //     errors.first_name = "The first name must contain only letters, spaces, and dash (-)"
    // } 

    // if (!formData.last_name.trim()) {
    //     errors.last_name = "Last name is required"
    // } else if(!/^[a-zA-Z- ]+$/.test(formData.last_name)){
    //     errors.last_name = "The last name must contain only letters, spaces, and dash (-)"
    // } 

    // if (!formData.username.trim()) {
    //     errors.username = "username is required"
    // } else if(!/^[a-zA-Z0-9_-]+$/.test(formData.username)){
    //     errors.username = "The username must contain only letters, numbers, underscore (_) and dash (-)"
    // } else if(formData.username.length < 6){
    //     errors.username = "The username must be 6 characters or more"
    // } else if (formData.username.length > 18) {
    //     errors.username = `The username must be 18 characters or less`;
    // }

    

    return errors;
};