import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../contexts/UserContext';
import Snackbar from '../components/Snackbar';

import Logo from "../assets/images/logo.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { validateFields } from "../components/valid";
const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export default function Signin() {
  const navigate = useNavigate();
  const { login } = useUser();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const sessionExpired = new URLSearchParams(location.search).get("sessionExpired");

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

    const [errors, setErrors] = useState({});

    let loggedIn = localStorage.getItem("user");
    loggedIn = JSON.parse(loggedIn);

    if (loggedIn?.id) {
        if (!!loggedIn.image_path, !!loggedIn.latitude, !!loggedIn.longitude, !!loggedIn.birthday, !!loggedIn.looking_for) {
            navigate('/connections');
        } else {
            navigate('/profile');
        }
    }
    
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, []);

  const mobileBreakpoint = 768;
  const isMobile = windowWidth <= mobileBreakpoint;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateFields(formData);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);

      axios.post(`${REACT_APP_BASE_API_URL}/users/login`, {
        email: formData.email,
        password: formData.password,
      })
        .then((response) => {
          setLoading(false);
          //setUser(response.data.data.data);
          login(response.data.data.data);
            setShowSuccessSnackbar(true);
            
            const { image_path, latitude, longitude, birthday, looking_for, is_admin_user } = response.data.data.data;


            if (!!image_path, !!latitude, !!longitude, !!birthday, !!looking_for) {
                navigate('/connections', { state: { loggedInUserData: response.data.data.data } });
            } else {
                navigate('/profile', { state: { loggedInUserData: response.data.data.data } });
            }
            
        })
        .catch((error) => {
          setLoading(false);
          console.error("API call error:", error);
          setShowErrorSnackbar(true);

          if (error.response && error.response.data && error.response.data.data && error.response.data.data.message) {
            setErrorSnackbarMessage(error.response.data.data.message);
          } else {
            setErrorSnackbarMessage("Something went wrong. Please try again later.");
          }
        });
    }
  };

  return (
    <>
      <Header className="fixed top-0 left-0 w-full" />

      <div className="bg-hero-pattern bg-cover">
        <main className="container mx-auto px-4 py-11 sm:px-6 lg:p-11 w-full max-w-md rounded">

          <div className="flex flex-col items-center bg-white p-6 rounded-3xl shadow space-y-4">
            <img src={Logo} alt="Logo" className="w-20 h-auto" />

            {sessionExpired &&
                <div>Your session has expired. Please login again to continue to access your account.</div>
            }

            <h1 className="text-lg text-black text-opacity-80 ">Login</h1>
            <Snackbar
              message="This is a success message!"
              type="success"
              showSnackbar={showSuccessSnackbar}
              setShowSnackbar={setShowSuccessSnackbar}
            />
            <Snackbar
              message={errorSnackbarMessage}
              type="error"
              showSnackbar={showErrorSnackbar}
              setShowSnackbar={setShowErrorSnackbar}
            />
            <form className="mt-2" onSubmit={handleSubmit}>

              <div className="flex flex-col relative w-full min-w-[200px] mt-4">
                <input name="email"
                  value={formData.email}
                  className="peer w-full rounded-[7px] border border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                  placeholder=" "
                  type="email"
                  autoComplete="email"
                  onChange={handleChange}
                />

                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                  Email*
                </label>
                {errors.email && <span className="py-1 text-red-500 text-xs italic">{errors.email}</span>}
              </div>


              <div className="relative w-full min-w-[200px] mt-4">
                <input name="password"
                  value={formData.password}
                  className="peer w-full rounded-[7px] border border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                  placeholder=" "
                  type="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                />

                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                  Password*
                </label>
                {errors.password && <span className="py-1 text-red-500 text-xs italic">{errors.password}</span>}
              </div>


              <div className="p-5 text-center">
                <button type="submit" className={`bg-borderLogin rounded-full shadow-md text-white font-bold inline-block py-1 px-12 ${loading ? "opacity-50" : ""}`} disabled={loading} >
                  {loading ? "Logging in..." : "LOGIN"}
                </button>
              </div>


              <div className="text-center">
                <a href="/forgot-password" className="text-customBlue underline">
                  Forgot password?
                </a>
              </div>
              <div className="text-center">
                <span className="text-gray-600 text-sm">
                  Don't have an account yet?{" "}
                </span>
                <a href="/signup" className="text-customBlue underline hover:underline-dark">
                  Sign Up
                </a>
              </div>
            </form>
          </div>  
        </main>
      </div>
      <Footer />
    </>
  )
}