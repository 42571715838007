import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Signup from './pages/Signup';
import Signin from './pages/Signin';
import ForgotPassword from './pages/ChangePassword';
import Verify from './pages/Verify';
import Connections from './pages/Connections';
import EditProfile from './pages/EditProfile';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import ViewProfile from './pages/viewProfile';
import Message from './pages/Message';
import UserChat from './pages/userChat';
import NotFound from './pages/NotFound';
// import { UserProvider } from './contexts/UserContext';
import { UserProvider } from './contexts/UserContext';
// import AuthenticatedRoute from './components/AuthenticatedRoute'; // Provide the correct path
import AdminPortal from './pages/AdminPortal';


function App() {
  return (
    <>
      <UserProvider>
        <Routes>
          <Route path='/signin' element={<Signin />}></Route>
          <Route path='/signup' element={<Signup />}></Route>
          <Route path='/forgot-password' element={<ForgotPassword />}></Route>
          <Route path='/verify' element={<Verify />}></Route>
          <Route path='/connections' element={<Connections />}></Route>
          <Route path='/edit' element={<EditProfile />}></Route>
          <Route path='/profile' element={<Profile />}></Route>
          <Route path='/settings' element={<Settings />}></Route>
          <Route path='/users/:username' element={<ViewProfile />}></Route>
          <Route path='/messages' element={<Message />}></Route>
          <Route path='/userchat' element={<UserChat />}></Route>
          <Route path='*' element={<NotFound />}></Route>
        </Routes>
      </UserProvider>

    </>
    /* <div className="App">
       <Signup />
   </div>*/
  );
}


export default App;
