import React, { useState, useEffect, useRef } from 'react';
import Header from "../components/Header"
import Footer from "../components/Footer"
import { useForm, clearErrors } from "react-hook-form";
import { object, string } from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
import axios from 'axios';
import { MultiSelect } from "react-multi-select-component";
import { useLocation } from 'react-router-dom';
import {
  Input,
  initTE,
} from "tw-elements";
import Snackbar from '../components/Snackbar';
import { useUser } from '../contexts/UserContext';
const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const CryptoJS = require('crypto-js');

function decryptData(encryptedData, key) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, key);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}

export default function Editprofile() {
  useEffect(() => {
    initTE({ Input });
  }, []);

  const locations = useLocation();
  const { data, reversedImagesArray: initialImagesArray } = locations.state;
  const [reversedImagesArray, setReversedImagesArray] = useState(initialImagesArray || []);


  const userData = locations.state?.data;
  console.log(userData);

  const { user, logout } = useUser();
  const navigate = useNavigate();

  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");
  const [previewFiles, setPreviewFiles] = useState([]);
  const [language, setLanguage] = useState([]);
  const [languageOptions, setLanguageOptions] = useState(null);
  const [languageSelected, setLanguageSelected] = useState([]);
  const [marital, setMarital] = useState([]);
  const [interest, setInterest] = useState([]);
  const [interestOptions, setInterestOptions] = useState(null);
  const [interestSelected, setInterestSelected] = useState([]);
  const [lookingFor, setLookingFor] = useState([]);
  const [profession, setProfession] = useState([]);
  const [starSign, setStarSign] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [location, setLocation] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isFileSelectionOpen, setIsFileSelectionOpen] = useState(false);
  const [errorBirthday, setErrorBirthday] = useState('');

  const fileInputRef = useRef(null);

  useEffect(() => {
    // Update the reversedImagesArray state when the value in locations.state changes
    setReversedImagesArray(locations.state.reversedImagesArray || []);
  }, [locations.state]);

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/language`)
      .then((response) => {
        setLanguage(response.data.data.data[0]);
        // const _languageOptions = response.data.data.data[0].map((lo) => { return ({ label: lo.title, value: lo.id }) });
        // setLanguageOptions(_languageOptions);
      })
      .catch((err) => {
        console.log(err)
      })
  }, []);

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/marital`)
      .then((response) => {
        //console.log(response.data.data.data[0])
        setMarital(response.data.data.data[0]);
      })
      .catch((err) => {
        console.log(err)
      })
  }, []);

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/interest`)
      .then((response) => {
        setInterest(response.data.data.data[0]);
        //const _interestOptions = response.data.data.data[0].map((int) => { return ({ label: int.title, value: int.id }) });
        //setInterestOptions(_interestOptions);

      })
      .catch((err) => {
        console.log(err)
      })
  }, []);

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/looking-for`)
      .then((response) => {
        //console.log(response.data)
        const lookingForData = response.data.data.data[0];
        setLookingFor(lookingForData.slice(0, -1));
      })
      .catch((err) => {
        console.log(err)
      })
  }, []);

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/profession`)
      .then((response) => {
        //console.log(response.data)
        setProfession(response.data.data.data[0]);
      })
      .catch((err) => {
        console.log(err)
      })
  }, []);

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/star-sign`)
      .then((response) => {
        //console.log(response.data)
        setStarSign(response.data.data.data[0]);
      })
      .catch((err) => {
        console.log(err)
      })
  }, []);


  const schema = object().shape({
    //  email: string().required('Email is required').email('Invalid email address'),
    // userName: string().required('Username is required'),
    firstName: string()
      .required('First name is required')
      .matches(/^[a-zA-Z- ]+$/, 'The first name must contain only letters, spaces, and dash (-)'),
    lastName: string()
      .required('Last name is required')
      .matches(/^[a-zA-Z- ]+$/, 'The last name must contain only letters, spaces, and dash (-)'),
    birthday: string().required('Birthday is required'),
    //gender: string().required('Gender is required'),
    //location_label: string().required('Location is required'),
    twitter: string().matches(
      /^$|((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Invalid URL format'
    ),
    instagram: string().matches(
      /^$|((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Invalid URL format'
    ),
    website: string().matches(
      /^$|((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Invalid URL format'
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, clearErrors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const handleFocus = () => {
  //   setIsFocused(true);
  // };


  // const handleSelectFiles = (event) => {
  //   event.preventDefault();
  //   console.log("Select Files button clicked");
  //   if (!isFileSelectionOpen) {
  //     setIsFileSelectionOpen(true);
  //     fileInputRef.current.click();
  //   }
  // };
  // // Function to handle file selection
  const handleFileInputChange = (event) => {
    event.preventDefault();
    setIsFileSelectionOpen(false);

    const files = event.target.files;

    // Convert files to an array
    const filesArray = Array.from(files);

    // Filter out files that exceed the maximum size
    const validFiles = filesArray.filter((file) => file.size <= MAX_FILE_SIZE_BYTES);

    // Check if adding the selected files will exceed the limit of 10
    if (reversedImagesArray.length + previewFiles.length + validFiles.length > 10) {
      setShowErrorSnackbar(true);
      setErrorSnackbarMessage("You can only upload a maximum of 10 images.");
      return;
    }

    // Update the previewFiles state with the selected files, up to a maximum of 10
    const remainingSlots = 10 - (reversedImagesArray.length + previewFiles.length);
    const filesToAdd = validFiles.slice(0, remainingSlots);
    setPreviewFiles([...previewFiles, ...filesToAdd]);

    // Show error Snackbar for oversized files
    const oversizedFiles = filesArray.filter((file) => file.size > MAX_FILE_SIZE_BYTES);
    if (oversizedFiles.length > 0) {
      window.scrollTo(0, 0);

      // Show error Snackbar
      setShowErrorSnackbar(true);
      setErrorSnackbarMessage(`The following files are too large and were not added to the upload list: ${oversizedFiles.map((file) => file.name).join(', ')}`);

      setTimeout(() => {
        setShowErrorSnackbar(false);
        setErrorSnackbarMessage('');
      }, 5000);
    }
  };

  const handleSelectFilesButtonClick = () => {
    fileInputRef.current.click();
  };
  const MAX_FILE_SIZE_BYTES = 10 * 1024 * 1024; // 10 MB

  const handleFileUpload = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const formData = new FormData();
      formData.append('AccessToken', user.cognito.AuthenticationResult.AccessToken);

      // Check file sizes before appending them to formData
      if (reversedImagesArray.length + previewFiles.length >= 11) {
        setShowErrorSnackbar(true);
        setErrorSnackbarMessage("You can only upload a maximum of 10 images.");
        return;
      }

      // Filter out files that exceed the maximum size
      const validFiles = previewFiles.filter((file) => {
        if (file.size > MAX_FILE_SIZE_BYTES) {
          // If the file size exceeds the maximum allowed size, show an error message
          setShowErrorSnackbar(true);
          setErrorSnackbarMessage(`File ${file.name} is too large. Maximum file size allowed is ${MAX_FILE_SIZE_BYTES / (1024 * 1024)} MB.`);
          return false; // Exclude the file from the valid files array
        }
        return true; // Include the file in the valid files array
      });

      // Append only valid files to formData
      validFiles.forEach((file) => {
        formData.append('files', file);
      });

      formData.append('cognito_user_id', userData.cognito_user_id);
      formData.append('user_id', userData.id);

      const uploadConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },
      };

      // Call your upload API here
      const uploadResponse = await axios.post(`${REACT_APP_BASE_API_URL}/users/upload`, formData, uploadConfig);

      const decryptedResponse = decryptData(uploadResponse.data, process.env.REACT_APP_ENCRYPTION_KEY);

      const { status, message, data } = decryptedResponse;

      if (status === 'success') {
        // Show success Snackbar
        window.scrollTo(0, 0);

        setShowSuccessSnackbar({
          message: message,
          type: 'success',
        });

        setTimeout(() => {
          setShowSuccessSnackbar(false);
        }, 5000);
      } else {
        // Show error Snackbar
        setShowErrorSnackbar(true);
        setErrorSnackbarMessage(message || 'Upload failed.');
      }
      // Handle the response if needed

      //console.log('File uploaded successfully', uploadResponse.data);
    } catch (error) {
      // Handle errors
      console.error('File upload failed', error.message);

      // Show error Snackbar
      setShowErrorSnackbar(true);
      // Set the error message
      setErrorSnackbarMessage(error.message || 'Upload failed.');
    }
  };



  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Filter out files that exceed the maximum size
      const validFiles = acceptedFiles.filter((file) => file.size <= MAX_FILE_SIZE_BYTES);

      // Check if adding the accepted files will exceed the limit of 10
      if (reversedImagesArray.length + previewFiles.length + validFiles.length > 10) {
        setShowErrorSnackbar(true);
        setErrorSnackbarMessage("You can only upload a maximum of 10 images.");
        return;
      }

      // Update the previewFiles state with only valid files, up to a maximum of 10
      const remainingSlots = 10 - (reversedImagesArray.length + previewFiles.length);
      const filesToAdd = validFiles.slice(0, remainingSlots);
      setPreviewFiles([...previewFiles, ...filesToAdd]);

      // Show error Snackbar for oversized files
      const oversizedFiles = acceptedFiles.filter((file) => file.size > MAX_FILE_SIZE_BYTES);
      if (oversizedFiles.length > 0) {
        window.scrollTo(0, 0);

        // Show error Snackbar
        setShowErrorSnackbar(true);
        setErrorSnackbarMessage(`The following files are too large and were not added to the upload list: ${oversizedFiles.map((file) => file.name).join(', ')}`);

        setTimeout(() => {
          setShowErrorSnackbar(false);
          setErrorSnackbarMessage('');
        }, 5000);
      }
    },
    accept: ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/*'],
  });




  // Function to handle deleting a preview by index
  const handlePreviewDelete = (index) => {
    const updatedPreviewFiles = [...previewFiles];
    updatedPreviewFiles.splice(index, 1);
    setPreviewFiles(updatedPreviewFiles);
  };



  const handleDeletePreview = async (index, imagePath, userId) => {
    try {
      // Make an API request to mark the image as deleted
      //const uploadResponse = await axios.post(`${REACT_APP_BASE_API_URL}/users/upload`, formData, uploadConfig);


      await axios.delete(`${REACT_APP_BASE_API_URL}/users/delete`, {
        data: {
          userId: userId,
          imagePath: imagePath
        }
      });

      // Update the reversedImagesArray state by removing the deleted image
      const updatedImagesArray = reversedImagesArray.filter((image, i) => i !== index);
      setReversedImagesArray(updatedImagesArray);
      const decryptedResponse = decryptData(response.data, process.env.REACT_APP_ENCRYPTION_KEY);

      // Check if the deletion was successful
      // if (decryptedResponse.status === 'success') {
      //   // Update the reversedImagesArray state by removing the deleted image
      //   const updatedImagesArray = reversedImagesArray.filter((image, i) => i !== index);
      //   setReversedImagesArray(updatedImagesArray);
      // } else {
      //   console.error('Error deleting image:', decryptedResponse.message);
      //   // Handle error (e.g., show an error message)
      // }

    } catch (error) {
      console.error('Error deleting image:', error);
      // Handle error (e.g., show an error message)
    }
  };
  const [editedData, setEditedData] = useState({
    firstName: userData?.first_name || '',
    lastName: userData?.last_name || '',
    birthday: userData?.birthday || '',
    gender: userData?.gender || '',
    location: userData?.label || '',
    interest: (userData?.interests || []).map(interest => ({ value: interest.interest_id, label: interest.title })) || [],
    language: (userData?.languages || []).map(language => ({ value: language.language_id, label: language.title })) || [],
    aboutMe: userData?.about_me || '',
    lookingFor: userData?.looking_for_id || '',
    profession: userData?.profession_id || '',
    maritalStatus: userData?.marital_status_id || '',
    starSign: userData?.starsign_id || '',
    children: userData?.children || '',
    instagram: userData?.instagram || '',
    twitter: userData?.twitter || '',
    website: userData?.website || '',
    location_longitude: userData?.longitude || undefined,
    location_latitude: userData?.latitude || undefined,
    location_label: userData?.label || undefined,
    location_country: userData?.country || undefined,
    location_region: userData?.region || undefined,
    location_sub_region: userData?.sub_region || undefined,
    location_municipality: userData?.municipality || undefined,
    location_street: userData?.street || undefined

    // Add other fields and their initial values here
  });

  const calculateAge = (birthdate) => {
    const selectedDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - selectedDate.getFullYear();
    const monthDiff = today.getMonth() - selectedDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < selectedDate.getDate())) {
      age--;
    }

    if (age < 18) {
      setErrorBirthday('Age must be more than 18.');
    } else {
      setErrorBirthday('');
    }

    return age;
  };

  useEffect(() => {
    calculateAge(editedData.birthday);
  }, [editedData.birthday]);

  const handleFieldChange = (fieldName, value) => {
    setEditedData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    if (fieldName === 'birthday') {
      calculateAge(value);
    }

  };

  useEffect(() => {
    if (editedData.interest.length > 0 || editedData.language.length > 0) {
      // Assuming editedData.interest contains the preselected data
      setInterestSelected(editedData.interest);
      setLanguageSelected(editedData.language);
    }
  }, [editedData.interest, editedData.language]);

  const handleInterestChange = (event) => {
    setInterestSelected(event);
  };

  const handleLanguageChange = (event) => {
    setLanguageSelected(event);
  };

  //console.log(editedData);

  const onSubmitHandler = async (data, event) => {

    //console.log(data.location_longitude);
    event.preventDefault();

    if (errorBirthday) {
      window.scrollTo(0, 0);
      // Show error Snackbar
      setShowErrorSnackbar(true);
      // Set the error message
      setErrorSnackbarMessage('Update failed.');

    } else {
      const locationLabelValue = editedData.location_label;
      const selectedInterestIds = interestSelected.map(i => `${i.value}`);
      const selectedLanguageIds = languageSelected.map(i => `${i.value}`);

      try {

        const updateProfile = await axios.patch(`${REACT_APP_BASE_API_URL}/users/profile/edit/${userData.cognito_user_id}`, {
          userId: userData.id,

          //address: data.location,
          AccessToken: user.cognito.AuthenticationResult.AccessToken,
          lookingFor: editedData.lookingFor || undefined,
          profession: editedData.profession || undefined,
          maritalStatus: editedData.maritalStatus || undefined,
          starsign: editedData.starSign || undefined,
          birthday: data.birthday || undefined,
          interests: selectedInterestIds?.length > 0 ? selectedInterestIds : undefined,
          languages: selectedLanguageIds?.length > 0 ? selectedLanguageIds : undefined,
          firstName: data.firstName || undefined,
          lastName: data.lastName || undefined,
          gender: data.gender === "Select a gender" ? undefined : data.gender,
          aboutMe: data.aboutMe || undefined,
          location: locationLabelValue || undefined,
          children: data.children || undefined,
          instagram: data.instagram || undefined,
          twitter: data.twitter || undefined,
          website: data.website || undefined,
          location_longitude: editedData.location_longitude || undefined,
          location_latitude: editedData.location_latitude || undefined,
          location_label: locationLabelValue || undefined,
          location_country: editedData.location_country || undefined,
          location_region: editedData.location_region || undefined,
          location_sub_region: editedData.location_sub_region || undefined,
          location_municipality: editedData.location_municipality || undefined,
          location_street: editedData.location_street || undefined,
        });


        //console.log('Profile updated successfully', updateProfile.data);

        const { status, data: responseData } = updateProfile.data;

        if (status === 'success') {
          // Show success Snackbar
          //setShowSuccessSnackbar(true);
          window.scrollTo(0, 0);

          setShowSuccessSnackbar({
            message: responseData.message,
            type: 'success',

          });

          // Redirect to profile page after 3 seconds
          setTimeout(() => {
            // Navigate logic here...
            // For example, using react-router-dom:
            navigate('/profile')
          }, 3000);
        } else {
          window.scrollTo(0, 0);
          // Show error Snackbar
          setShowErrorSnackbar(true);
          // Set the error message
          setErrorSnackbarMessage(responseData?.message || 'Update failed.');
        }
      } catch (error) {
        window.scrollTo(0, 0);
        console.error('Error:', error.response?.data?.message || 'Update failed.');
        // Show error Snackbar
        setShowErrorSnackbar(true);
        // Set the error message
        setErrorSnackbarMessage(error.response?.data?.message || 'Update failed.');
        if (error.response?.data?.data?.message.includes("Auth failed")) {
          logout();
          navigate("/signin?sessionExpired=true");
        }

      }
    }


  };



  const [hiddenFields, setHiddenFields] = useState({
    location_longitude: '',
    location_latitude: '',
    location_country: '',
    location_region: '',
    location_sub_region: '',
    location_municipality: '',
    location_street: ''
  });


  //setSuggestions(userData.address);

  const handleFetchLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          setLatitude(lat);
          setLongitude(lon);

          if (latitude && longitude) {
            try {

              const response = await axios.post(`${REACT_APP_BASE_API_URL}/users/location`, {
                lat: latitude,
                long: longitude,
                //value: "saskatoon"
              });

              //console.log("=======current location=======================");
              //console.log(response);

              const responseData = response.data.data.data[0];

              //console.log("Response data place:", responseData);

              // Extract data from the response
              const {
                Place: {
                  Geometry: { Point },
                  Country,
                  Region,
                  SubRegion,
                  Street,
                  Label,
                },
                storedValue

              } = responseData;

              // Update hidden fields state
              setEditedData((prevData) => ({
                ...prevData,
                location_longitude: Point[0],
                location_latitude: Point[1],
                location_country: Country,
                location_region: Region,
                location_sub_region: SubRegion,
                location_municipality: SubRegion, // Assuming municipality and sub-region are the same
                location_street: Street || "", // Use an empty string if Street is undefined
                location_label: storedValue,
                location: storedValue,
              }));

              // Set suggestions state
              setSuggestions(storedValue);

              //console.log("Hidden fields after update:", hiddenFields);

            } catch (error) {

              console.error('API Error:', error.message);
            }
          }

        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              //alert("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              alert("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              alert("The request to get user location timed out.");
              break;
            case error.UNKNOWN_ERROR:
              alert("An unknown error occurred.");
              break;
            default:
              break;
          }
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  useEffect(() => {
    if (userData && userData.label) {
      setSuggestions(userData.label);
    }
  }, [userData]);



  const handleInputChange = async (e) => {
    const inputValue = e.target.value;
    //alert(inputValue);
    setSuggestions(inputValue);

    if (inputValue.trim() === '') {
      setShowSuggestions(false);
      return;
    }

    // Perform API call to fetch label based on inputValue
    try {

      const response = await axios.post(`${REACT_APP_BASE_API_URL}/users/location`, {
        value: inputValue
      });


      //console.log(response.data);
      const locations = response.data.data.data;
      //console.log("==locations===")
      //console.log(locations);
      setLocation(locations);
      setShowSuggestions(true);



    } catch (error) {

      console.error('API Error:', error.message);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const label = suggestion.storedValue;

    setSuggestions(label); // Update the suggestions state with the selected label
    //  setValue('location_label', label);

    // Update the editedData state using the label
    setEditedData((prevData) => ({
      ...prevData,
      location_longitude: suggestion.Place.Geometry.Point[0],
      location_latitude: suggestion.Place.Geometry.Point[1],
      location_country: suggestion.Place.Country,
      location_region: suggestion.Place.Region,
      location_sub_region: suggestion.Place.Region,
      location_municipality: suggestion.Place.SubRegion,
      location_street: suggestion.Place.Street,
      location_label: label,
      location: label,
      // Set the label as the value for the location field
    }));

    setShowSuggestions(false);
  };


  return (
    <>
      <Header className="fixed top-0 left-0 w-full" />
      <div className="bg-connectionBg bg-cover">
        <main className="container mx-auto px-2 py-11 sm:px-6 lg:p-11 w-full max-w-md md:max-w-md md:min-w-md lg:max-w-[70%] rounded">


          <div className="w-full flex flex-col flex-wrap">

            <div className=" w-full ml-4 mt-4">
              <h5 className="text-black text-opacity-80 text-2xl">Edit profile</h5>
            </div>
            <Snackbar
              message={showSuccessSnackbar.message}
              type="success"
              showSnackbar={showSuccessSnackbar}
              setShowSnackbar={setShowSuccessSnackbar}
            />
            <Snackbar
              message={errorSnackbarMessage}
              type="error"
              showSnackbar={showErrorSnackbar}
              setShowSnackbar={setShowErrorSnackbar}
            />
            <br></br>

            <div className="w-full mb-5">

              <div className="w-full mt-4 flex flex-col p-6 rounded-3xl shadow-custom">
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="w-full flex relative align-top">
                    <div className="w-full h-11 flex-col relative m-2 align-top">
                      <input
                        {...register('username')}
                        name="userName"
                        className="peer w-full rounded-[7px] border border-t-transparent border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                        placeholder=" "
                        value={userData?.username || '-'}
                        readOnly

                      />

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Username*
                      </label>

                    </div>

                  </div>

                  <div className="w-full flex relative align-top">
                    <div className="w-full h-11 flex-col relative m-2 align-top">
                      <input
                        {...register('email')}
                        name="email"
                        className="peer w-full rounded-[7px] border border-t-transparent border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0" placeholder=" "
                        autoComplete="email"
                        type="text"
                        value={userData?.email || '-'}

                        readOnly

                      />

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Email*
                      </label>

                    </div>
                  </div>

                  {/* Dropzone for file uploads */}
                  <div className="m-2">
                    <div className="w-full flex-col relative align-top rounded-md">
                      <div className="w-full flex flex-col relative m-2 align-top text-xs text-gray-500">
                        <p>Multiple-image upload guidelines:</p>
                        <ul>
                          <li>1) Max image size is 10 MB</li>
                          <li>2) Allowed image types: jpg/jpeg, png, gif</li>
                          <li>3) Your last uploaded image will be your profile image.</li>
                          <li>4) You can select up to 10 images.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="w-full flex-col h-80 relative align-top rounded-md shadow-imageUpload">
                      <div className="w-full flex flex-col relative align-top m-2 p-3">
                        {/* Check if both reversedImagesArray and previewFiles are empty */}
                        {(reversedImagesArray.length === 0 && previewFiles.length === 0) ? (
                          <div className="text-gray-500 text-center">
                            <p>Drag 'n' drop some files here, or click to select files</p>
                          </div>
                        ) : (
                          <div className="w-full flex flex-wrap">
                            {/* Render previews for images from reversedImagesArray */}
                            {reversedImagesArray.map((image, index) => (
                              <div key={index} className="m-2 relative">
                                <img
                                  src={`https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${image.path}`}
                                  alt={`Preview ${index}`}
                                  className="rounded-md w-20 h-20 object-contain"
                                />

                                <button
                                  type='button'
                                  className="absolute top-[-8px] right-[-8px] bg-borderLogin text-white rounded-full p-1 w-6 h-6 flex items-center justify-center"
                                  onClick={() => handleDeletePreview(index, image.path, userData.id)}
                                >
                                  X
                                </button>
                              </div>
                            ))}
                            {/* Render previews for uploaded files */}
                            {previewFiles.map((file, index) => (
                              <div key={index} className="m-2 relative">
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={`Uploaded File ${index}`}
                                  className="rounded-md w-20 h-20 object-contain"
                                />
                                <button
                                  type='button'
                                  className="absolute top-[-8px] right-[-8px] bg-borderLogin text-white rounded-full p-1 w-6 h-6 flex items-center justify-center"
                                  onClick={() => handlePreviewDelete(index)}
                                >
                                  X
                                </button>





                              </div>
                            ))}
                          </div>
                        )}
                        <div className="flex justify-center">
                          <input type="file" accept="image/*" multiple style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileInputChange} />
                          <button type="button" className="inline-block p-2 bg-borderLogin text-white rounded-md cursor-pointer max-w-[150px] truncate"
                            onClick={handleSelectFilesButtonClick}>Select Files</button>
                          <span style={{ margin: '0 10px' }}></span>
                          <button
                            type='button'
                            className="inline-block p-2 bg-borderLogin text-white rounded-md cursor-pointer max-w-[150px] truncate"
                            onClick={handleFileUpload}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div className="flex flex-wrap mt-2">
                    <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                      <div className="w-full h-11 flex-col relative m-2 align-top">
                        <input
                          {...register('firstName')}
                          name="firstName"
                          className="peer w-full rounded-[7px] border border-t-transparent border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                          placeholder=" "
                          type="text"
                          value={editedData.firstName}
                          onChange={(e) => handleFieldChange('firstName', e.target.value)}
                        />

                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                          Firstname*
                        </label>
                        {errors.firstName && (
                          <p className="text-xs italic text-red-500">{errors.firstName.message}</p>
                        )}

                      </div>
                    </div>

                    <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                      <div className="w-full h-11 flex-col relative m-2 align-top">
                        <input
                          {...register('lastName')}
                          name="lastName"
                          className="peer w-full rounded-[7px] border border-t-transparent border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                          placeholder=" "
                          type="text"
                          value={editedData.lastName}
                          onChange={(e) => handleFieldChange('lastName', e.target.value)}
                        />

                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                          Lastname*
                        </label>
                        {errors.lastName && (
                          <p className="text-xs italic text-red-500">{errors.lastName.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                      <div className="w-full h-11 flex-col relative m-2 align-top">
                        <input
                          {...register('birthday')}
                          name="birthday"
                          className="peer w-full rounded-[7px] border border-t-transparent border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                          placeholder=" "
                          type="date"
                          value={editedData.birthday}
                          onChange={(e) => handleFieldChange('birthday', e.target.value)}
                        />

                        <label htmlFor="birthday" className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                          Birthday*
                        </label>
                        {errorBirthday && (
                          <p className="text-xs italic text-red-500">{errorBirthday}</p>
                        )}
                        {errors.birthday && (
                          <p className="text-xs italic text-red-500">{errors.birthday.message}</p>
                        )}
                      </div>
                    </div>


                    <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                      <div className="w-full h-11 flex-col relative m-2 align-top">

                        <select
                          {...register('gender')}
                          name="gender"
                          value={editedData.gender}
                          onChange={(e) => handleFieldChange('gender', e.target.value)}
                          className="peer w-full rounded-[7px] border border-t-transparent border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >
                          <option disabled selected>Select a gender</option>
                          <option value="1">Female</option>
                          <option value="2">Male</option>

                        </select>

                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                          Gender*
                        </label>
                        {errors.gender && (
                          <p className="text-xs italic text-red-500">{errors.gender.message}</p>
                        )}

                      </div>
                    </div>
                  </div>

                  <div className="w-full">
                    <hr className="border-t border-solid border-gray-300 m-2" />
                  </div>

                  <div className="w-full flex-col relative align-top">
                    <div className="h-11 flex-col relative m-2 align-top">
                      <input
                        {...register('location')}
                        name="location"
                        className="peer w-full rounded-[7px] border border-t-transparent border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                        placeholder=" "
                        autoComplete="location"
                        type="text"
                        value={suggestions}
                        onChange={(e) => handleInputChange(e)}
                      />

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Location*
                      </label>
                      {errors.location && (
                        <p className="text-xs italic text-red-500">{errors.location.message}</p>
                      )}

                      {showSuggestions && (
                        <ul className="absolute z-10 left-0 mt-2 w-full bg-white rounded border border-gray-300 shadow-md">
                          {location.map((suggestion, index) => (
                            <li
                              key={index}
                              className="text-black cursor-pointer px-3 py-2 hover:bg-gray-100"
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion.storedValue}
                            </li>
                          ))}
                        </ul>
                      )}

                    </div>
                    <div className={`flex ${errors.location ? 'mt-4' : ''}`}>
                      <div>
                        <button
                          type="button"
                          onClick={handleFetchLocation}
                          className="ml-2 p-2 bg-borderLogin text-white rounded-md"
                        >
                          Get current location
                        </button>
                      </div>
                      <div className="min-w-[50%] max-w-[50%] flex align-top">
                        <div className="w-full inline-flex flex-colalign-top hidden">
                          <h6 className="text-base">Longitude: </h6>
                          <p className="text-gray-500 text-base font-normal">{longitude}</p>
                        </div>

                        <div className="w-full inline-flex flex-col align-top hidden">
                          <h6 className="text-base">Latitude: </h6>
                          <p className="text-gray-500 text-base font-normal">{latitude}</p>
                        </div>

                      </div>
                    </div>
                  </div>
                  <input {...register('location_longitude')} type="hidden" name="location_longitude" value={editedData ? editedData.location_longitude : undefined} />
                  <input {...register('location_latitude')} type="hidden" name="location_latitude" value={editedData ? editedData.location_latitude : undefined}
                  />
                  <input {...register('location_label')} type="hidden" name="location_label" value={editedData ? editedData.location_label : undefined} />

                  <input {...register('location_country')} type="hidden" name="location_country" value={editedData ? editedData.location_country : undefined} />
                  <input {...register('location_region')} type="hidden" name="location_region" value={editedData ? editedData.location_region : undefined} />
                  <input {...register('location_sub_region')} type="hidden" name="location_sub_region" value={editedData ? editedData.location_sub_region : undefined} />
                  <input {...register('location_municipality')} type="hidden" name="location_municipality" value={editedData ? editedData.location_municipality : undefined} />
                  <input {...register('location_street')} type="hidden" name="location_street" value={editedData ? editedData.location_street : undefined} />


                  <div className="w-full">
                    <hr className="border-t border-solid border-gray-300 m-2" />
                  </div>

                  <div className="w-full flex relative align-top">
                    <div className="w-full h-40 flex-col relative m-2 align-top ">
                      <textarea
                        {...register('aboutMe')}
                        name="aboutMe"
                        className="peer w-full h-full resize-none rounded-[7px] border border-t-transparent border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                        placeholder=""
                        type="text"
                        value={editedData.aboutMe}
                        onChange={(e) => handleFieldChange('aboutMe', e.target.value)}
                      />

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        About Me
                      </label>
                    </div>
                  </div>

                  <div className="w-full flex flex-wrap">
                    <div className="w-full flex relative align-top">
                      <div className="w-full h-11 flex-col relative align-top">


                        {interest && (
                          <>
                            <MultiSelect
                              labelledBy="Select your interests"
                              hasSelectAll={false}
                              options={interest.map(status => ({ value: status.id, label: status.title }))}
                              value={interestSelected}
                              onChange={e => handleInterestChange(e)}
                              className="text-placeHolderText text-sm m-2"
                            />
                            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 top-0.5 flex w-full -ml-2 select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:ml-4 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-2 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                              Interests
                            </label>
                          </>
                        )}


                      </div>
                    </div>

                    <div className="min-w-[100%] max-w-[100%] flex relative align-top mt-4">
                      <div className="w-full h-11 flex-col relative align-top">

                        {language && (
                          <>
                            <MultiSelect
                              labelledBy="Select your languages"
                              hasSelectAll={false}
                              options={language.map(status => ({ value: status.id, label: status.title }))}
                              value={languageSelected}
                              onChange={e => handleLanguageChange(e)}
                              className="text-placeHolderText text-sm m-2"
                            />
                            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 top-0.5 flex w-full -ml-2 select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:ml-4 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-2 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                              Languages
                            </label>
                          </>
                        )}

                      </div>
                    </div>

                    <div className="min-w-[50%] max-w-[50%] flex relative align-top mt-4">
                      <div className="w-full h-11 flex-col relative m-2 align-top">
                        <select
                          {...register('lookingFor')}
                          name="lookingFor"
                          value={editedData.lookingFor}
                          onChange={(e) => handleFieldChange('lookingFor', e.target.value)}
                          className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >
                          <option disabled selected></option>
                          {lookingFor.map((status) => (
                            <option key={status.id} value={status.id}>{status.title}</option>
                          ))}

                        </select>

                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                          Looking for
                        </label>
                      </div>

                    </div>

                    <div className="min-w-[50%] max-w-[50%] flex relative align-top mt-4">
                      <div className="w-full h-11 flex-col relative m-2 align-top">
                        <select
                          {...register('profession')}
                          name="profession"
                          value={editedData.profession}
                          onChange={(e) => handleFieldChange('profession', e.target.value)}
                          className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >
                          <option disabled selected></option>
                          {profession.map((status) => (
                            <option key={status.id} value={status.id}>{status.title}</option>
                          ))}
                        </select>

                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                          Profession
                        </label>
                      </div>

                    </div>

                    <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                      <div className="w-full h-11 flex-col relative m-2 align-top">
                        <select
                          {...register('maritalStatus')}
                          name="maritalStatus"
                          value={editedData.maritalStatus}
                          onChange={(e) => handleFieldChange('maritalStatus', e.target.value)}
                          className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >
                          <option disabled selected></option>
                          {marital.map((status) => (
                            <option key={status.id} value={status.id}>{status.title}</option>
                          ))}

                        </select>

                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                          Martial Status
                        </label>
                      </div>

                    </div>

                    <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                      <div className="w-full h-11 flex-col relative m-2 align-top">
                        <select
                          {...register('starSign')}
                          name="starSign"
                          value={editedData.starSign}
                          onChange={(e) => handleFieldChange('starSign', e.target.value)}
                          className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >
                          <option disabled selected></option>
                          {starSign.map((status) => (
                            <option key={status.id} value={status.id}>{status.title}</option>
                          ))}

                        </select>

                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                          Starsign
                        </label>
                      </div>

                    </div>

                    <div className="min-w-[50%] max-w-[50%] flex  align-top">
                      <div className="w-full h-11 flex-col relative m-2 align-top">
                        <select
                          {...register('children')}
                          name="children"
                          value={editedData.children}
                          onChange={(e) => handleFieldChange('children', e.target.value)}
                          className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >
                          <option disabled selected></option>
                          <option value="1">Yes</option>
                          <option value="2">No</option>
                          <option value="3">Wants children someday</option>
                          <option value="4">Doesn't want children</option>

                        </select>

                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                          Children
                        </label>
                      </div>

                    </div>

                    <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                      <div className="w-full h-11 flex-col relative m-2 align-top">
                        <input
                          {...register('instagram')}
                          name="instagram"
                          value={editedData.instagram}
                          onChange={(e) => handleFieldChange('instagram', e.target.value)}
                          className="peer w-full rounded-[7px] border border-t-transparent border-inputBorder  bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                          placeholder=" "
                        />

                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                          Instagram
                        </label>
                        {errors.instagram && (
                          <p className="text-xs italic text-red-500">{errors.instagram.message}</p>
                        )}


                      </div>
                    </div>

                    <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                      <div className="w-full h-11 flex-col relative m-2 align-top">
                        <input
                          {...register('twitter')}
                          name="twitter"
                          value={editedData.twitter}
                          onChange={(e) => handleFieldChange('twitter', e.target.value)}
                          className="peer w-full rounded-[7px] border border-t-transparent border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                          placeholder=" "
                        />

                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                          Twitter
                        </label>
                        {errors.twitter && (
                          <p className="text-xs italic text-red-500">{errors.twitter.message}</p>
                        )}


                      </div>
                    </div>


                    <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                      <div className="w-full h-11 flex-col relative m-2 align-top">
                        <input
                          {...register('website')}
                          name="website"
                          value={editedData.website}
                          onChange={(e) => handleFieldChange('website', e.target.value)}
                          className="peer w-full rounded-[7px] border border-t-transparent border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                          placeholder=" "
                        />

                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                          Website
                        </label>
                        {errors.website && (
                          <p className="text-xs italic text-red-500">{errors.website.message}</p>
                        )}


                      </div>
                    </div>

                  </div>
                  <div className="p-3 m-3 text-center">
                    <button type="submit" className="bg-borderLogin rounded-full shadow-md text-white font-bold inline-block py-1 px-12">
                      UPDATE
                    </button>
                  </div>

                </form>
              </div>

            </div>

          </div >
        </main >
      </div >
      <Footer />

    </>
  )
}