import React, { useState, useEffect } from 'react';
import Header from "../components/Header"
import Footer from "../components/Footer"
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { GoArrowLeft, GoArrowRight, GoChevronLeft, GoChevronRight } from "react-icons/go";
import { RiSkipLeftLine, RiSkipRightLine } from "react-icons/ri";
import { ImUser } from "react-icons/im"
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
  TETabs,
  TETabsItem,
} from "tw-elements-react";
import { FaTimes } from "react-icons/fa"
import { Carousel, initTE } from "tw-elements";
import { differenceInYears } from 'date-fns';

import { useUser } from '../contexts/UserContext';

import premium from "../assets/images/premium.png";
import connect from "../assets/images/connect.png";
import verified from "../assets/images/verified.png";
import connectM from "../assets/images/1.png";
import premiumM from "../assets/images/2.png";
import verifiedM from "../assets/images/3.png";
import Snackbar from '../components/Snackbar';


initTE({ Carousel });
const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const decryptData = (encryptedData, secretKey) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};
export default function Connection() {

  const [userData, setUserData] = useState([]);
  const [totalData, setTotalData] = useState();
  const [marital, setMarital] = useState([]);
  const [interest, setInterest] = useState([]);
  const [lookingFor, setLookingFor] = useState([]);
  const [profession, setProfession] = useState([]);
  const [starSign, setStarSign] = useState([]);
  const { user, logout } = useUser();

  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showLoginSnackbar, setShowLoginSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");
  const tabs = [
    { id: 1, name: "Dating" },
    { id: 2, name: "Friendships" },
    { id: 3, name: "Community" },
    { id: 4, name: "All" },
  ];
  const [selectedTab, setSelectedTab] = useState(4);
  const [clipPath, setClipPath] = useState('none');
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffSet] = useState(0);
  //console.log(currentPage);
  const email = user ? user.email : null;
  const AccessToken = user ? user.cognito?.AuthenticationResult?.AccessToken : null;
  const itemsPerPage = 12;
  const navigate = useNavigate();
  const [formData, setFormData] = useState(new FormData());
  //console.log("Selected tab is ",selectedTab)

  useEffect(() => {
    function updateClipPath() {
      if (selectedTab !== null) {
        setClipPath(window.innerWidth <= 640 ? 'polygon(22% 0%, 100% 0%, 100% 100%, 0% 100%)' : 'polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%)');
      } else {
        setClipPath('none');
      }
    }

    updateClipPath();

    function handleResize() {
      updateClipPath();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [selectedTab]);


  useEffect(() => {
    const fetchData = async () => {
      if (AccessToken && email) {
        let conn;
        if (selectedTab === 4) {

          const form = document.getElementById('searchForm');
          const updatedFormData = new FormData(form);
          updatedFormData.append('AccessToken', AccessToken);
          updatedFormData.append('offset', offset);
          updatedFormData.append('limit', itemsPerPage);
          updatedFormData.delete('lookingFor');

          const formObject = {};
          updatedFormData.forEach((value, key) => {
            formObject[key] = value;
          });

          conn = formObject;

        } else {
          const form = document.getElementById('searchForm');
          const updatedFormData = new FormData(form);
          const formObject = {};
          updatedFormData.forEach((value, key) => {
            formObject[key] = value;
          });
          formObject.AccessToken = AccessToken;
          formObject.offset = offset;
          formObject.limit = itemsPerPage;
          formObject.lookingFor = selectedTab;
          conn = formObject;
        }

        setSelectedTab(selectedTab);

        try {
          const response = await axios.post(`${REACT_APP_BASE_API_URL}/users/connections`, conn);
          //console.log(response.data);
          const decryptedResponse = decryptData(response.data, process.env.REACT_APP_ENCRYPTION_KEY); // Replace 'yourSecretKey' with your actual secret key
          setUserData(decryptedResponse.data.data.users);
          setTotalData(decryptedResponse.data.data.totalCount);
        } catch (err) {
          console.log(err);
          if (err.response?.data?.data?.message.includes("Auth failed")) {
            logout();
            navigate("/signin?sessionExpired=true");
          }
        }
      }
    };

    fetchData();
  }, [offset, AccessToken, selectedTab, formData]);


  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/marital`)
      .then((response) => {
        //console.log(response.data.data.data[0])
        setMarital(response.data.data.data[0]);
      })
      .catch((err) => {
        console.log(err)
      })
  }, []);

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/interest`)
      .then((response) => {
        //console.log(response.data)
        setInterest(response.data.data.data[0]);
      })
      .catch((err) => {
        console.log(err)
      })
  }, []);

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/looking-for`)
      .then((response) => {
        //console.log(response.data)
        const lookingForData = response.data.data.data[0];
        setLookingFor(lookingForData.slice(0, -1));
      })
      .catch((err) => {
        console.log(err)
      })
  }, []);

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/profession`)
      .then((response) => {
        //console.log(response.data)
        setProfession(response.data.data.data[0]);
      })
      .catch((err) => {
        console.log(err)
      })
  }, []);

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/star-sign`)
      .then((response) => {
        //console.log(response.data)
        setStarSign(response.data.data.data[0]);
      })
      .catch((err) => {
        console.log(err)
      })
  }, []);

  const [longitude, setLongitude] = useState(undefined);
  const [latitude, setLatitude] = useState(undefined);

  useEffect(() => {
    if (user?.latitude && user?.longitude) {
      setLongitude(user.longitude);
      setLatitude(user.latitude);
    }
  }, [user]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          setLatitude(lat);
          setLongitude(lon);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              //alert("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              alert("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              alert("The request to get user location timed out.");
              break;
            case error.UNKNOWN_ERROR:
              alert("An unknown error occurred.");
              break;
            default:
              break;
          }
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentProfileIndex, setCurrentProfileIndex] = useState(0);

  const handleLookingForSelect = (value) => {
    //console.log("incoming  ", value);
    setSelectedTab(parseInt(value));
  };

  useEffect(() => { console.log("selected tab has changed to " + selectedTab) }, [selectedTab]);


  // Function to handle opening modal and set modal data
  const handleModalOpen = (user, index) => {

    setCurrentProfileIndex(index);

    if (user) {

      const userId = user ? user.id : null;
      axios.post(`${REACT_APP_BASE_API_URL}/users/profile/main`, {
        userId: userId,
        AccessToken: AccessToken
      }).then((response) => {
        const decryptedResponse = decryptData(response.data, process.env.REACT_APP_ENCRYPTION_KEY);

        //retrive interests array
        const interestsArray = decryptedResponse.data.data.interests || [];
        const languagesArray = decryptedResponse.data.data.languages || [];


        // extract title value and join with comma(,)
        const interestsString = interestsArray.map(interest => interest.title).join(', ');
        const languagesString = languagesArray.map(language => language.title).join(', ');

        // Reverse the order of the images array
        //const reversedImagesArray = [...response.data.data.data.images].reverse();
        //console.log(reversedImagesArray);

        // Set the interest data with rela api data in the state
        setModalData({ ...decryptedResponse.data.data, interests: interestsString, languages: languagesString });
        setShowModal(true);


      })
        .catch((err) => {
          console.log(err)
        })
    }
  };



  const redirectToUserProfile = (modalData) => {
    if (!user) {
      setShowLoginSnackbar(true);
    }

    setShowModal(false);
    const { username } = modalData;
    //console.log(modalData);
    navigate(`/users/${username}`, { state: { userData: modalData } });

  };


  const handleTabClick = async (tabId, tabName) => {
    const apiUrl = `${REACT_APP_BASE_API_URL}/users/connections`;

    setCurrentPage(1);
    setOffSet(0);
    // Set the selected tab
    setSelectedTab(tabId);
    // let conn; // Declare conn outside the if-else block

    // if (tabId == 4) {
    //   conn = {
    //     email: email,
    //     AccessToken: user.cognito?.AuthenticationResult?.AccessToken
    //   };
    // } else {
    //   conn = {
    //     email: email,
    //     AccessToken: user.cognito?.AuthenticationResult?.AccessToken,
    //     lookingFor: tabId
    //   };
    // }
    // const form = document.getElementById('searchForm');
    // const formData = new FormData(form);
    // formData.append('AccessToken', conn.AccessToken);
    // formData.append('offset', offset);
    // formData.append('limit', itemsPerPage);

    // if (conn.lookingFor) {
    //   formData.append('lookingFor', conn.lookingFor);
    // }
    // // else {
    // //   formData.delete('lookingFor'); // Remove 'lookingFor' if tabId is not from 1, 2, or 3
    // // }

    // // Append each key-value pair to the FormData object
    // const formDataObject = {};
    // formData.forEach((value, key) => {
    //   formDataObject[key] = value;
    // });
    // // Now conn is accessible outside the if-else block
    // //console.log(conn);

    // if (AccessToken) {
    //   try {
    //     // Use a separate async function to perform the asynchronous operation
    //     const response = await fetch(apiUrl, {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify(formDataObject), // Send the entire connection object directly
    //     });

    //     const data = await response.json();
    //     //console.log(data.data);

    //     if (data.status === 'success') {
    //       console.log(data.data);
    //       setUserData(data.data.data.users);
    //       setTotalData(data.data.data.totalCount);
    //       // Handle success, e.g., update state or UI
    //     } else {
    //       console.error(data.data);
    //       setUserData({
    //         message: data.data.message,
    //         data: [],
    //       });
    //     }
    //   } catch (err) {
    //     console.error('Error during API request:', err);
    //     if (err.response?.data?.data?.message.includes("Auth failed")) {
    //         logout();
    //         navigate("/signin?sessionExpired=true");
    //     }
    //   }
    // }
  };


  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 12;


  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = userData.slice(indexOfFirstItem, indexOfLastItem);




  const handlePrevious = () => {
    const prevSlide = (currentSlide - 1 + modalData.images.length) % modalData.images.length;
    setCurrentSlide(prevSlide);
  };

  const handleNext = () => {
    const nextSlide = (currentSlide + 1) % modalData.images.length;
    setCurrentSlide(nextSlide);
  };

  const handleNextProfile = () => {
    if (currentProfileIndex < userData.length - 1) {
      setCurrentSlide(0);
      setCurrentProfileIndex((prevIndex) => {
        const newIndex = prevIndex + 1;
        // const { first_name, last_name, username, birthday, gender, label, about_me, images } = currentItems[newIndex];
        // const imagePaths = images ? images.map(image => image.path).reverse() : [];
        // setModalData({ first_name, last_name, username, birthday, gender, label, about_me, images: imagePaths });
        //setModalData(currentItems[newIndex]);
        // console.log(userData[newIndex].id);
        //  axios.get(`${REACT_APP_BASE_API_URL}/users/profile/main/${userData[newIndex].id}`)


        const userId = userData[newIndex].id;
        axios.post(`${REACT_APP_BASE_API_URL}/users/profile/main`, {
          userId: userId,
          AccessToken: AccessToken
        }).then((response) => {
          //console.log(response.data);
          //retrive interests array
          const decryptedResponse = decryptData(response.data, process.env.REACT_APP_ENCRYPTION_KEY);

          //retrive interests array
          const interestsArray = decryptedResponse.data.data.interests || [];
          const languagesArray = decryptedResponse.data.data.languages || [];


          // extract title value and join with comma(,)
          const interestsString = interestsArray.map(interest => interest.title).join(', ');
          const languagesString = languagesArray.map(language => language.title).join(', ');

          // Reverse the order of the images array
          //const reversedImagesArray = [...response.data.data.data.images].reverse();
          //console.log(reversedImagesArray);

          // Set the interest data with rela api data in the state
          setModalData({ ...decryptedResponse.data.data, interests: interestsString, languages: languagesString });
          setShowModal(true);


          //console.log(modalData);

        })
          .catch((err) => {
            console.log(err)
            setShowErrorSnackbar(true);
          })
        return newIndex;
      });
    } else {
      setShowSuccessSnackbar(true);
    }
  };

  const handlePreviousProfile = () => {
    if (currentProfileIndex > 0) {
      setCurrentSlide(0);
      setCurrentProfileIndex((prevIndex) => {
        const newIndex = prevIndex - 1;
        // const { first_name, last_name, username, birthday, gender, label, about_me, images } = currentItems[newIndex];
        // const imagePaths = images ? images.map(image => image.path).reverse() : [];
        // setModalData({ first_name, last_name, username, birthday, gender, label, about_me, images: imagePaths });
        //setModalData(currentItems[newIndex]); 

        const userId = userData[newIndex].id;
        axios.post(`${REACT_APP_BASE_API_URL}/users/profile/main`, {
          userId: userId,
          AccessToken: AccessToken
        }).then((response) => {

          //console.log(response.data);
          //retrive interests array
          const decryptedResponse = decryptData(response.data, process.env.REACT_APP_ENCRYPTION_KEY);

          //retrive interests array
          const interestsArray = decryptedResponse.data.data.interests || [];
          const languagesArray = decryptedResponse.data.data.languages || [];


          // extract title value and join with comma(,)
          const interestsString = interestsArray.map(interest => interest.title).join(', ');
          const languagesString = languagesArray.map(language => language.title).join(', ');


          // Set the interest data with rela api data in the state
          setModalData({ ...decryptedResponse.data.data, interests: interestsString, languages: languagesString });
          setShowModal(true);


          //console.log(modalData);

        })
          .catch((err) => {
            console.log(err)
            setShowErrorSnackbar(true);
          })
        return newIndex;
      });
    } else {
      setShowSuccessSnackbar(true);
    }
  };

  const handleSearch = async () => {

    setCurrentPage(1);
    setOffSet(0);
    const form = document.getElementById('searchForm');
    const formData = new FormData(form);
    const formObject = {};
    formData.forEach((value, key) => {
      formObject[key] = value;
    });
    console.log(formObject);
    const selectedTab = formObject.name ? (formObject.lookingFor ? formObject.lookingFor : 4) : (formObject.lookingFor ? formObject.lookingFor : 4);

    setSelectedTab(parseInt(selectedTab));

    try {
      const form = document.getElementById('searchForm');
      const formData = new FormData(form);

      formData.append('AccessToken', AccessToken);
      formData.append('offset', offset);
      formData.append('limit', itemsPerPage);
      // formObject.AccessToken = user.cognito?.AuthenticationResult?.AccessToken;
      // formObject.offset = offset;
      // formObject.limit = itemsPerPage;

      // if (selectedTab === 4) {
      //   formData.delete('lookingFor');
      // }
      // else {
      //   formData.append('lookingFor', selectedTab);
      //      // Remove 'lookingFor' if tabId is not from 1, 2, or 3
      // }

      const formObject = {};
      formData.forEach((value, key) => {
        formObject[key] = value;
      });
      console.log(formObject);
      const selectedTab = formObject.lookingFor ? formObject.lookingFor : 4;
      console.log(selectedTab);
      setSelectedTab(parseInt(selectedTab));

      if (parseInt(selectedTab) === 4) {
        delete formObject.lookingFor;
      } else {
        formObject.lookingFor = selectedTab;
      }

      const response = await fetch(`${REACT_APP_BASE_API_URL}/users/connections`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formObject),
      });

      const encryptedData = await response.text();
      const decryptedData = decryptData(encryptedData, process.env.REACT_APP_ENCRYPTION_KEY); // Replace 'yourSecretKey' with your actual secret key


      if (decryptedData.status === 'success') {
        setUserData(decryptedData.data.data.users);
        setTotalData(decryptedData.data.data.totalCount);
      } else {
        console.error(decryptedData.data);
        // Handle errors, e.g., display an error message
      }
    } catch (err) {
      console.error('Error:', err);
      if (err.response?.data?.data?.message.includes("Auth failed")) {
        logout();
        navigate("/signin?sessionExpired=true");
      }
    }
  };

  const totalPages = Math.ceil(totalData / itemsPerPage);
  //console.log("total", totalPages);

  const handleClickNext = () => {
    //setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
    setCurrentPage((prevPage) => {
      const nextPage = prevPage + 1;
      const newOffSet = (nextPage - 1) * itemsPerPage;
      setOffSet(newOffSet);
      return nextPage;
    });
  };

  const handleClickPrev = () => {
    //setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    setCurrentPage((prevPage) => {
      const nextPage = prevPage - 1;
      const newOffSet = (nextPage - 1) * itemsPerPage;
      setOffSet(newOffSet);
      return nextPage;
    });
  };

  const handleClickFirst = () => {
    setCurrentPage(1);
    setOffSet(0);
  };

  const handleClickLast = () => {
    setCurrentPage(totalPages);
    const lastPageOffset = (totalPages - 1) * itemsPerPage;
    // console.log(lastPageOffset);
    setOffSet(lastPageOffset);
  };


  return (
    <>
      <Header className="fixed top-0 left-0 w-full" />
      <div className="bg-connectionBg bg-cover">
        <main className="container mx-auto px-2 py-11 sm:px-6 lg:p-11 w-full max-w-md md:max-w-[70%] lg:max-w-[70%] rounded">
          <div className="flex flex-col">
            <div className="ml-4 mt-4">
              <h5 className="text-black text-opacity-80 text-2xl">Connections</h5>
            </div>

            <div className="mb-5">
              <div className="w-full mt-4 flex flex-wrap items-center md:p-6 lg:p-6 rounded-3xl shadow-custom">

                <form id="searchForm" className='flex flex-wrap'>

                  <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                    <div className="w-full h-11 flex-col relative m-4 align-top">
                      <input name="name"
                        className="peer w-full rounded-[7px] border border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                        placeholder=" "
                        //  autoComplete="name"
                        type="text"

                      />
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Name
                      </label>
                    </div>

                  </div>

                  <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                    <div className="w-full h-11 flex-col relative m-4 align-top">
                      <select name="distanceKM"
                        className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                        placeholder=" " >

                        <option value="" selected>Please select distance</option>
                        <option value="100">100 KM (60+ Miles)</option>
                        <option value="200">200 KM (120+ Miles)</option>
                        <option value="300">300 KM (180+ Miles)</option>
                        <option value="400">400 KM (240+ Miles)</option>
                        <option value="500">500 KM (300+ Miles)</option>
                        <option value="1000">1000 KM (600+ Miles)</option>
                        <option value="21000">Worldwide</option>
                      </select>
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Location Distance
                      </label>
                    </div>
                  </div>

                  <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                    <div className="w-full h-11 flex-col relative m-4 align-top">
                      <select
                        name="gender"
                        className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >
                        <option value="" selected>Please select gender</option>
                        <option value="1">Female</option>
                        <option value="2">Male</option>
                      </select>

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Gender
                      </label>
                    </div>
                  </div>

                  <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                    <div className="w-full h-11 flex-col relative m-4 align-top">
                      <select
                        name="age"
                        className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >
                        <option value="" selected>Please select an age</option>
                        <option value="1">18 - 29</option>
                        <option value="2">30 - 39</option>
                        <option value="3">40 - 49</option>
                        <option value="4">50 - 59</option>
                        <option value="5">60+</option>

                      </select>

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Age
                      </label>
                    </div>

                  </div>

                  <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                    <div className="w-full h-11 flex-col relative m-4 align-top">
                      <select
                        name="lookingFor"
                        onChange={(e) => { handleLookingForSelect(e.target.value) }}
                        className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >
                        <option value="" selected>Please select lookingfor</option>
                        {lookingFor.map((status) => (
                          <option key={status.id} value={status.id}>{status.title}</option>
                        ))}

                      </select>

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Looking for
                      </label>
                    </div>

                  </div>

                  <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                    <div className="w-full h-11 flex-col relative m-4 align-top">
                      <select
                        name="profession"
                        className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >
                        <option value="" selected >Please select profession</option>
                        {profession.map((status) => (
                          <option key={status.id} value={status.id}>{status.title}</option>
                        ))}

                      </select>

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Profession
                      </label>
                    </div>

                  </div>

                  <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                    <div className="w-full h-11 flex-col relative m-4 align-top">
                      <select
                        name="interest"
                        className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >
                        <option value="" selected>Please select an interest</option>
                        {interest.map((status) => (
                          <option key={status.id} value={status.id}>{status.title}</option>
                        ))}


                      </select>

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Interests
                      </label>
                    </div>

                  </div>

                  <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                    <div className="w-full h-11 flex-col relative m-4 align-top">
                      <select
                        name="maritalStatus"
                        className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >
                        <option value="" selected>Please select marital status</option>
                        {marital.map((status) => (
                          <option key={status.id} value={status.id}>{status.title}</option>
                        ))}

                      </select>

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Martial Status
                      </label>
                    </div>

                  </div>

                  <div className="min-w-[50%] max-w-[50%] flex relative align-top">
                    <div className="w-full h-11 flex-col relative m-4 align-top">
                      <select
                        name="starsign"
                        className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >

                        <option value="" selected>Please select starsign</option>
                        {starSign.map((status) => (
                          <option key={status.id} value={status.id}>{status.title}</option>
                        ))}


                      </select>

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Starsign
                      </label>
                    </div>

                  </div>

                  <div className="min-w-[50%] max-w-[50%] flex  align-top">
                    <div className="w-full h-11 flex-col relative m-4 align-top">
                      <select
                        name="children"
                        className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"                                    >
                        <option value="" selected>Please select children</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                        <option value="3">Wants children someday</option>
                        <option value="4">Doesn't want children</option>

                      </select>

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Children
                      </label>
                      <input type='hidden' value={email} name="email" id="email" ></input>
                    </div>

                  </div>

                  <div className="w-full flex">
                    <div className="min-w-[50%] max-w-[50%] flex align-top">
                      <div className="w-full inline-flex flex-col m-4 align-top">
                        <button type="button" onClick={handleSearch} className="bg-borderLogin rounded-full shadow-md text-white font-bold inline-block py-2 px-12">
                          SEARCH
                        </button>
                      </div>

                      <div className="w-full inline-flex flex-col m-4 align-top">
                        <button type="submit" className="border-2 border-borderLogin rounded-full text-borderLogin font-bold inline-block py-2 px-12">
                          RESET
                        </button>
                      </div>

                    </div>


                    {/* <div className="min-w-[50%] max-w-[50%] flex align-top">
                    <div className="w-full inline-flex flex-col m-4 align-top hidden">
                      <h6 className="text-base">Longitude: </h6>
                      <p className="text-gray-500 text-base font-normal">{longitude}</p>
                    </div> */}
                    <input type="hidden" value={longitude} name='long' />
                    <input type="hidden" value={latitude} name='lat' />

                    {/* 
                    <div className="w-full inline-flex flex-col m-4 align-top hidden">
                      <h6 className="text-base">Latitude: </h6>
                      <p className="text-gray-500 text-base font-normal">{latitude}</p>
                    </div>

                  </div> */}
                  </div>

                  <div className="w-full">
                    <hr className="border-t border-solid border-gray-300 m-4 py-2" />
                  </div>
                </form>

                <div className="w-full flex">
                  <div className="flex gap-1 ml-1 mr-1 md:overflow-visible">
                    {tabs.map((tab, index) => (
                      <div
                        key={tab.id}
                        className={`border-borderLogin hover:border-hoverTab hover:bg-hoverTab group flex-1 skew-x-[20deg] transform border ${selectedTab === tab.id ? 'bg-borderLogin' : ''} sm:text-xs md:text-base`}
                        style={{
                          borderBottom: 'none',
                          clipPath: index === 0 ? clipPath : 'none',

                        }}
                        onClick={() => handleTabClick(tab.id, tab.name)}
                      >
                        <div className="m-4 md:m-4 items-center justify-center flex -skew-x-[20deg] -transform bg-transparent md:px-5 text-center text-borderLogin first-letter:uppercase">
                          <b className={`block text-xs md:text-base group-hover:text-white ${selectedTab === tab.id ? 'text-white' : 'text-borderLogin'}`}>{tab.name}</b>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="w-full">
                  <hr className="border-t border-solid border-borderLogin ml-4 mr-4" />
                </div>

                <div className="p-4">
                  <div className="flex flex-wrap pb-2">
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-5">
                      {userData && userData.map((user, index) => (
                        <div key={user.id} className='flex-grow group rounded-md bg-white shadow-md p-4 sm:h-30 sm:w-30 md:w-60 md:h-50 lg:w-60 lg:h-50 cursor-pointer transition-transform duration-300 transform hover:scale-105 hover:shadow-lg'

                          onClick={() => handleModalOpen(user, index)}>
                          <div className="flex flex-wrap items-center justify-between">
                            <div className="truncate">
                              <h6 className="font-medium md:text-2xl text-xl leading-6 tracking-tight mb-4 text-customBlue underline">

                                {user.first_name}
                                {user.birthday && `, ${differenceInYears(new Date(), new Date(user.birthday))}`}

                              </h6>
                            </div>



                          </div>
                          <div className="h-40 items-center justify-center rounded-md bg-gray-300">
                            <a style={{ display: 'block', width: '100%', height: '100%' }}>
                              {user.image_path ? (
                                <div
                                  className="object-cover h-40 rounded-md p-2"
                                  style={{
                                    backgroundImage: `url(https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${user.image_path})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                  }}
                                />
                              ) : (
                                <div className="flex items-center justify-center">
                                  <ImUser className="text-white w-40 h-40" />
                                </div>
                              )}
                            </a>
                            <div style={{ position: 'absolute', bottom: "10px", left: "20px" }}>
                              {user.permission_names && typeof user.permission_names === 'string' && (
                                <div className="flex items-center justify-center mb-4">
                                  {(() => {
                                    const permissions = user.permission_names.split(',').map(permission => permission.trim());

                                    const hasConnectPermission = permissions.includes('user-connect-content') || permissions.includes('c');
                                    const hasPremiumPermission = permissions.includes('user-premium-content') || permissions.includes('p');
                                    const hasVerifiedPermission = permissions.includes('user-verified-content') || permissions.includes('v');
                                    return (
                                      <>
                                        {hasConnectPermission && (
                                          <img
                                            src={connect}
                                            alt="Connect"
                                            className="w-8 h-8 mx-1"
                                            title='Connect'
                                          />
                                        )}

                                        {hasPremiumPermission && (
                                          <img
                                            src={premium}
                                            alt="Premium"
                                            className="w-8 h-8 mx-1"
                                            title='Premium'
                                          />
                                        )}

                                        {hasVerifiedPermission && (
                                          <img
                                            src={verified}
                                            alt="Verified"
                                            className="w-8 h-8 mx-1"
                                            title='Verified'
                                          />
                                        )}
                                      </>
                                    );
                                  })()}
                                </div>
                              )}
                            </div>
                          </div>


                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <TEModal show={showModal} setShow={setShowModal}>

                  <TEModalDialog className="p-5 sm:max-w-[60%] md:max-w-[60%] lg:max-w-[60%] xl:max-w-[65%]">
                    <TEModalContent>
                      <div className='w-50 m-2 item-center'>
                        <Snackbar
                          message="You have reached the end of your search results on this tab"
                          type="success"
                          showSnackbar={showSuccessSnackbar}
                          setShowSnackbar={setShowSuccessSnackbar}
                        />
                        <Snackbar
                          message={errorSnackbarMessage}
                          type="error"
                          showSnackbar={showErrorSnackbar}
                          setShowSnackbar={setShowErrorSnackbar}
                        />
                        <Snackbar
                          message="Please login to connect!"
                          type="success"
                          showSnackbar={showLoginSnackbar}
                          setShowSnackbar={setShowLoginSnackbar}
                        />
                        <Snackbar
                          message={errorSnackbarMessage}
                          type="error"
                          showSnackbar={showErrorSnackbar}
                          setShowSnackbar={setShowErrorSnackbar}
                        />
                      </div>
                      <TEModalHeader>

                        <button
                          type="button"
                          className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                          aria-label="Previous"
                          onClick={handlePreviousProfile}
                        >
                          <GoChevronLeft className="h-6 w-6" />

                        </button>
                        <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                          {modalData?.username || '-'}
                        </h5>
                        <button
                          type="button"
                          className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                          aria-label="Next"
                          onClick={handleNextProfile}
                        >
                          <GoChevronRight className="h-6 w-6" />

                        </button>

                      </TEModalHeader>

                      <TEModalBody className="h-auto">

                        <div className="w-full flex align-top">
                          <div className="w-full flex flex-wrap items-center justify-between">
                            <div className="flex m-2">
                              <h6 className="font-semibold text-base">
                                Name
                              </h6>

                              <p className="ml-4 text-gray-500 text-base font-normal">
                                {modalData?.first_name || '-'}
                                {modalData?.birthday && `, ${differenceInYears(new Date(), new Date(modalData?.birthday))}`}
                              </p>
                            </div>
                            <div className="flex m-2 justify-end flex-grow">
                              {modalData?.permission_names && typeof modalData?.permission_names === 'string' && (
                                <div className="flex items-center justify-center">
                                  {(() => {
                                    const permissions = modalData.permission_names?.split(',').map(permission => permission.trim());

                                    const hasConnectPermission = permissions.includes('user-connect-content') || permissions.includes('c');
                                    const hasPremiumPermission = permissions.includes('user-premium-content') || permissions.includes('p');
                                    const hasVerifiedPermission = permissions.includes('user-verified-content') || permissions.includes('v');

                                    return (
                                      <>
                                        {hasConnectPermission && (
                                          <img
                                            src={connectM}
                                            alt=""
                                            className="w-22 h-10 mx-1"
                                            title='Connect'
                                          />
                                        )}

                                        {hasPremiumPermission && (
                                          <img
                                            src={premiumM}
                                            alt=""
                                            className="w-22 h-10 mx-1"
                                            title='Premium'
                                          />
                                        )}

                                        {hasVerifiedPermission && (
                                          <img
                                            src={verifiedM}
                                            alt=""
                                            className="w-22 h-10 mx-1"
                                            title='Verified'
                                          />
                                        )}
                                      </>
                                    );
                                  })()}
                                </div>
                              )}
                            </div>
                          </div>

                        </div>

                        <div className="relative"
                          data-te-carousel-init
                          data-te-ride="carousel"
                          id="carouselExampleControls">

                          <div className="flex w-full h-80 bg-gray-300 items-center text-center justify-center rounded-md overflow-hidden after:clear-both after:block after:content-['']">


                            {modalData?.images && modalData.images.length > 0 ? (
                              modalData.images.map((imagePath, index) => (
                                <div
                                  key={index}
                                  className={`relative float-left w-full h-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none ${index === currentSlide ? 'block' : 'hidden'
                                    }`}
                                  data-te-carousel-item
                                  data-te-carousel-active={index === currentSlide ? 'true' : 'false'}
                                >
                                  <img
                                    src={`https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${imagePath.path}`}
                                    alt=""
                                    className="rounded-md h-full w-full object-contain"
                                  />
                                </div>
                              ))
                            ) : (
                              <div className="flex items-center justify-center">
                                <ImUser className="text-white w-40 h-40" />
                              </div>
                            )}


                          </div>


                          <button
                            className="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-customBlue opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-customBlue hover:no-underline hover:opacity-90 hover:outline-none focus:text-customBlue focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                            type="button"
                            onClick={handlePrevious}

                            disabled={currentSlide === 0}

                            data-te-target="#carouselExampleControls"
                            data-te-slide="prev">
                            <span className="inline-block h-8 w-8">
                              <GoChevronLeft className="h-8 w-8" />
                            </span>
                            <span
                              className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                            >Previous</span
                            >
                          </button>

                          <button
                            className="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-customBlue opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-customBlue hover:no-underline hover:opacity-90 hover:outline-none focus:text-customBlue focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                            type="button"
                            onClick={handleNext}
                            data-te-target="#carouselExampleControls"
                            data-te-slide="next">
                            <span className="inline-block h-8 w-8">
                              <GoChevronRight className="h-8 w-8" />
                            </span>
                            <span
                              className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                            >Next</span
                            >
                          </button>

                        </div>

                        <div className="w-full flex flex-col align-top p-4">
                          <div className="flex">
                            <div className="m-2">
                              <h6 className="font-semibold text-base">

                                Gender

                              </h6>
                            </div>
                            <div className="m-2">
                              <p className="text-gray-500 text-base font-normal">
                                {modalData?.gender === 1 ? 'Female' : modalData?.gender === 2 ? 'Male' : '-'}
                              </p>
                            </div>
                          </div>

                          <div className="flex">
                            <div className="m-2">
                              <h6 className="font-semibold text-base">

                                Location

                              </h6>
                            </div>
                            <div className="m-2">
                              <p className="text-gray-500 text-base font-normal">
                                {modalData?.label || '-'}
                              </p>
                            </div>
                          </div>

                          <div className="flex">
                            <div className="m-2">
                              <h6 className="font-semibold text-base">

                                About me

                              </h6>
                            </div>
                            <div className="m-2">
                              <p className="text-gray-500 text-base font-normal">
                                {modalData?.about_me || '-'}
                              </p>
                            </div>
                          </div>

                        </div>
                      </TEModalBody>

                      <TEModalFooter className="flex justify-between">


                        <TERipple rippleColor="light">
                          <button
                            type="button"
                            className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                            onClick={() => {
                              setShowModal(false);
                              setCurrentSlide(0);
                            }}
                          >
                            Close
                          </button>
                        </TERipple>

                        <TERipple rippleColor="light">
                          <button
                            type="button"
                            className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                            onClick={() => redirectToUserProfile(modalData)}
                          >
                            Connect me
                          </button>
                        </TERipple>

                      </TEModalFooter>
                    </TEModalContent>
                  </TEModalDialog>
                </TEModal>

                <div className="p-4">
                  <nav>
                    <ul className="flex flex-wrap items-center">
                      <li className="m-1">
                        <button className={`flex items-center justify-center border-2 border-solid border-gray-300 rounded ${currentPage === 1 ? 'opacity-50 disabled cursor-not-allowed' : ''}`}
                          onClick={handleClickFirst}
                        >
                          <RiSkipLeftLine className="h-5 w-5 m-4" />
                        </button>
                      </li>
                      <li className="m-1">
                        <button className={`flex items-center justify-center border-2 border-solid border-gray-300 rounded ${currentPage === 1 ? 'opacity-50 disabled cursor-not-allowed' : ''}`}
                          onClick={handleClickPrev}
                        >
                          <GoArrowLeft className={`h-5 w-5 m-4 currentPage === 1 ? 'opacity-50' : ''`} />
                        </button>
                      </li>
                      <li className="m-1">
                        <div className="px-4 flex items-center justify-center">
                          ...
                        </div>
                      </li>
                      <li className="m-1">
                        <button className={`flex items-center justify-center border-2 border-solid border-gray-300 rounded ${currentPage === totalPages ? 'opacity-50 disabled cursor-not-allowed' : ''}`}
                          onClick={handleClickNext}>
                          <GoArrowRight className="h-5 w-5 m-4" />
                        </button>
                      </li>
                      <li className="m-1">
                        <button className={`flex items-center justify-center border-2 border-solid border-gray-300 rounded ${currentPage === totalPages ? 'opacity-50 disabled cursor-not-allowed' : ''}`}
                          onClick={handleClickLast}>
                          <RiSkipRightLine className="h-5 w-5 m-4" />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

          </div >
        </main >
      </div >
      <Footer />

    </>
  )
}
