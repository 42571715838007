import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import 'tw-elements-react/dist/css/tw-elements-react.min.css';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import App from './App';
//import dotenv from 'dotenv';

const root = ReactDOM.createRoot(document.getElementById('root'));
//dotenv.config();

const isMobileDevice = () => {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  return width < 768;
};



const isMobile = isMobileDevice();
if (window.location.pathname === '/' ) {
  window.location.href = '/signin';
}
root.render(
  <StrictMode>
    <BrowserRouter>
      <UserProvider>
        <App />
      </UserProvider>
    </BrowserRouter>
  </StrictMode>,
);
