import React, { useState } from 'react';
import Footer from "../components/Footer";
import Header from "../components/Header"
import axios from 'axios';
const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

console.log(REACT_APP_BASE_API_URL);

    
export default function Settings() {

    const [userEmail, setUserEmail] = useState(null);
    const [terminateUserResponse, setTerminateUserResponse] = useState(null);
   
    const handleChangeEmail = (e) => setUserEmail(e.target.value);

    const submitDisableUser = () => {


        axios.post(`${REACT_APP_BASE_API_URL}/admin/disable`, { email: userEmail })
            .then(r => {
                setTerminateUserResponse("The user was successfully terminated");
                console.log(r);
            })
            .catch(e => {
                setTerminateUserResponse("There was an error. You can see more info in the console");
                console.log(e);
            });
            
    };


    
    return (
        <>
            <Header className="fixed top-0 left-0 w-full" />
            <div className="bg-connectionBg bg-cover">
                <main className="container mx-auto px-2 py-11 sm:px-6 lg:p-11 w-full max-w-md lg:max-w-[70%] rounded">
                    <div className="flex flex-col flex-wrap">
                        <div className="ml-4 mt-4">
                            <h5 className="text-black text-opacity-80 text-2xl">Admin Portal</h5>
                        </div>
                        <div className="mt-4 mb-5 flex flex-col p-6 rounded-3xl shadow-custom">
                            <div className="w-full flex flex-wrap items-center align-top p-1">

                                <div className="w-full items-center p-1 mt-2">
                                    <h5 className="text-black text-opacity-80 text-2xl">Disable User</h5>
                                </div>

                                <div className="flex h-11 flex-col relative w-full min-w-[200px] mt-4">
                                    <input
                                        onChange={handleChangeEmail}
                                        name="userEmail"
                                        className="peer w-full rounded-[7px] border border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                                        placeholder=" "
                                    />
                                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                                        User Email
                                    </label>
                                </div>

                                <div className="mt-4 text-center">
                                    <button onClick={submitDisableUser} className="bg-borderLogin hover:bg-hoverButton rounded-full shadow-md text-white font-bold inline-block py-1 px-12" >
                                        TERMINATE
                                    </button>
                                </div>
                                {terminateUserResponse &&
                                    <div className="py-4">
                                        {terminateUserResponse}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </>
    );
}