import React, { useState, useEffect } from 'react';
import Header from "../components/Header"
import Footer from "../components/Footer"
import { useNavigate } from 'react-router-dom';
import { CiEdit } from "react-icons/ci";
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { differenceInYears } from 'date-fns';
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { ImUser } from "react-icons/im"
import { Carousel, initTE } from "tw-elements";
initTE({ Carousel });
import { useUser } from '../contexts/UserContext';
const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const secretkey = process.env.REACT_APP_ENCRYPTION_KEY;

const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
};
export default function Profile() {

    const [data, setData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const { user, logout } = useUser();
    const AccessToken = user ? user.cognito?.AuthenticationResult?.AccessToken : null;

    const navigate = useNavigate();

    const redirectToEditProfile = () => {
        const reversedImagesArray = [...userData.images].reverse();
        //console.log(reversedImagesArray);
        navigate('/edit', { state: { data, reversedImagesArray } });
    };



    const hiddenBirthday = user ? user.hidden_birthday : null;
    console.log(hiddenBirthday);

    useEffect(() => {
        if (user && user.id) {
            const id = user.id;
            axios.post(`${REACT_APP_BASE_API_URL}/users/profile/main`, {
                userId: id,
                AccessToken: AccessToken
            }).then((response) => {
                //retrive interests array
                const decryptedResponse = decryptData(response.data, secretkey);
                console.log("===========");
                console.log(decryptedResponse);
                setData(decryptedResponse.data.data);

                // Retrieve interests and languages arrays
                const interestsArray = decryptedResponse.data.data.interests || [];
                const languagesArray = decryptedResponse.data.data.languages || [];

                // Extract title value and join with comma(,)
                const interestsString = interestsArray.map(interest => interest.title).join(', ');
                const languagesString = languagesArray.map(language => language.title).join(', ');

                // Set the interest data with related API data in the state
                setUserData({ ...decryptedResponse.data.data, interests: interestsString, languages: languagesString });
                //console.log(response.data.data.data);


            })
                .catch((err) => {
                    console.log(err)
                    if (err.response?.data?.data?.message.includes("Auth failed")) {
                        logout();
                        navigate("/signin?sessionExpired=true");
                    }
                })
        }
    }, [user]);

    const handlePrevious = () => {
        const prevSlide = (currentSlide - 1 + userData.images.length) % userData.images.length;
        setCurrentSlide(prevSlide);
    };

    const handleNext = () => {
        const nextSlide = (currentSlide + 1) % userData.images.length;
        setCurrentSlide(nextSlide);
    };

    return (
        <>
            <Header className="fixed top-0 left-0 w-full" />
            <div className="bg-connectionBg bg-cover">
                <main className="container mx-auto px-2 py-11 sm:px-6 lg:p-11 w-full max-w-md lg:max-w-[70%] rounded">
                    <div className="flex flex-col">
                        <div className="ml-4 mt-4">
                            <h5 className="text-black text-opacity-80 text-2xl">Profile</h5>
                        </div>

                        <div className="mb-5">

                            <div className="mt-4 flex flex-col p-6 rounded-3xl shadow-custom">

                                <div className="w-full flex flex-wrap items-center justify-between align-top p-1">


                                    <h5 className="text-xl flex-grow text-center font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                                        {userData?.username || '-'}
                                    </h5>

                                    <button
                                        type="button"
                                        className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                        onClick={redirectToEditProfile}
                                    >
                                        <CiEdit className="h-8 w-8" />

                                    </button>
                                </div>

                                <div className="w-full">
                                    <hr className="border-t border-solid border-gray-300 m-2" />
                                </div>

                                <div className="w-full flex flex-col align-top p-4">
                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Name

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.first_name || '-'} {userData?.last_name || '-'}
                                                {hiddenBirthday
                                                    ? ''
                                                    : userData.birthday && `, ${differenceInYears(new Date(), new Date(userData.birthday))}`
                                                }

                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex hidden">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Email

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.email || '-'}
                                            </p>
                                        </div>
                                    </div>
                                </div>



                                <div className="relative"
                                    data-te-carousel-init
                                    data-te-ride="carousel"
                                    id="carouselExampleControls">

                                    <div className="flex w-full h-80 bg-gray-300 items-center text-center justify-center rounded-md overflow-hidden after:clear-both after:block after:content-['']">

                                        {userData?.images && userData.images.length > 0 ? (
                                            userData.images.map((imagePath, index) => (
                                                <div
                                                    key={index}
                                                    className={`relative float-left w-full h-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none ${index === currentSlide ? 'block' : 'hidden'
                                                        }`}
                                                    data-te-carousel-item
                                                    data-te-carousel-active={index === currentSlide ? 'true' : 'false'}
                                                >
                                                    <img
                                                        src={`https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${imagePath.path}`}
                                                        alt=""
                                                        className="rounded-md h-full w-full object-contain"
                                                    />
                                                </div>
                                            ))
                                        ) : (
                                            <div className="flex items-center justify-center">
                                                <ImUser className="text-white w-40 h-40" />
                                            </div>
                                        )}
                                    </div>


                                    <button
                                        className="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-customBlue opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-customBlue hover:no-underline hover:opacity-90 hover:outline-none focus:text-customBlue focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                                        type="button"
                                        onClick={handlePrevious}

                                        disabled={currentSlide === 0}

                                        data-te-target="#carouselExampleControls"
                                        data-te-slide="prev">
                                        <span className="inline-block h-8 w-8">
                                            <GoChevronLeft className="h-8 w-8" />
                                        </span>
                                        <span
                                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                        >Previous</span
                                        >
                                    </button>

                                    <button
                                        className="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-customBlue opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-customBlue hover:no-underline hover:opacity-90 hover:outline-none focus:text-customBlue focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                                        type="button"
                                        onClick={handleNext}
                                        data-te-target="#carouselExampleControls"
                                        data-te-slide="next">
                                        <span className="inline-block h-8 w-8">
                                            <GoChevronRight className="h-8 w-8" />
                                        </span>
                                        <span
                                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                        >Next</span
                                        >
                                    </button>

                                </div>



                                <div className="w-full flex flex-col align-top p-4">
                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Gender

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.gender === 1 ? 'Female' : userData?.gender === 2 ? 'Male' : '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Location

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.label || '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                About me

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.about_me || '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Interests

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.interests || '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Languages

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.languages || '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Looking For

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.looking_for || '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Profession

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.profession || '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Marital Status

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.marital_status || '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Do you have children?

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.children === 1 ? 'Yes' : userData?.children === 2 ? 'No' : userData?.children === 3 ? 'Wants children someday' : userData?.children === 4 ? "Doesn't want children" : '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Starsign

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.starsign || '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Instagram

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.instagram || '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Twitter

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.twitter || '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <div className="m-2">
                                            <h6 className="font-semibold text-base">

                                                Website

                                            </h6>
                                        </div>
                                        <div className="m-2">
                                            <p className="text-gray-500 text-base font-normal">
                                                {userData?.website || '-'}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </main>
            </div>
            <Footer />

        </>
    )
}