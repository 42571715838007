import React, { useState, useEffect } from "react"
import Logo from "../assets/images/logo.png"
//import {Link} from "react-router-dom"
import {
    FaSignOutAlt,
    FaBars,
    FaTimes,
    FaCheckCircle,
    FaRegHeart,


} from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { MdBlurOn, MdLan, MdOutlineVerifiedUser, MdOutlineSettings, MdMessage } from "react-icons/md";
import { useUser } from '../contexts/UserContext';

const Header = ({ isMobile: initialIsMobile }) => {

    const [isMobile, setIsMobile] = useState(initialIsMobile);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { logout, user } = useUser();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const userVerified = user ? user.permission_names : null;

    const handleResize = () => {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        setIsMobile(width < 768);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        setIsLoggedIn(!!user); // Use !!user to check if user is truthy
        //console.log(user);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [user]);

    const handleLogout = () => {
        //console.log("Logging out...");
        // Call the logout function from the UserContext
        logout();
        //console.log("Logout successful");
        // Optionally, you can update the local state to reflect the user's logged-out status
        setIsLoggedIn(false);
        // You can also redirect the user to the login page or any other desired page after logout
        // Example: history.push('/signin');
    }



    return (
        <div className="bg-white text-white shadow-lg sticky top-0 border-b-6 border-borderBottom z-50">
            <div className={`px-4 flex ${isMobile ? 'items-center' : 'justify-between items-center'} relative`}>

                <div className="flex ml-2 mr-16">

                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="text-black p-3"
                    >
                        <FaBars className="h-5 w-5" />
                    </button>

                    <a href="/signin" className="py-3">
                        <img
                            alt="Logo"
                            className="w-44 h-11"
                            src={Logo} />
                    </a>

                </div>


                {isMenuOpen && (
                    <div className="absolute top-14 left-4 bg-white shadow-custom mt-2 py-2 rounded-md min-w-4 min-h-4">
                        <ul className="py-2">
                            <nav>
                                <li className="px-4 py-1.5 flex items-center">
                                    <button
                                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                                        className="p-2 rounded-full text-black">

                                        <FaTimes className="h-6 w-6" />
                                    </button>

                                    <div className="flex mr-4 ml-2">
                                        <a href="/signin">
                                            <img
                                                alt="Logo"
                                                className="h-11"
                                                src={Logo} />
                                        </a>

                                    </div>
                                </li>
                                {!isLoggedIn ? (
                                    <ul className="py-1">
                                        <hr className="border-t border-solid border-gray-300" />

                                        <li className="flex justify-start items-center relative text-left w-full box-border">
                                            <a href="https://unjected.com/#verified" target="_blank" rel="noopener noreferrer" className="flex flex-1 justify-start items-center relative text-left box-border">
                                                <div className="px-4 text-opacity-54 inline-flex text-gray-700">
                                                    <MdOutlineVerifiedUser className="h-6 w-6" />
                                                </div>

                                                <div className="flex-1 mt-4 mb-4 px-4">
                                                    <span className="font-normal text-base leading-6 tracking-tight block text-gray-700">Memberships</span>
                                                </div>
                                            </a>
                                        </li>

                                        <hr className="border-t border-solid border-gray-300" />

                                        <li className="bg-teal-200 bg-opacity-10 flex justify-start items-center relative text-left w-full box-border">
                                            <a href="/signin" className="flex flex-1 justify-start items-center relative text-left box-border">
                                                <div className="px-4 text-opacity-54 inline-flex text-gray-700">
                                                    <FaSignOutAlt className="h-6 w-6" />
                                                </div>

                                                <div className="flex-1 mt-4 mb-4 px-4">
                                                    <span className="font-normal text-base leading-6 tracking-tight block text-gray-700">Log In</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>

                                ) : (

                                    userVerified === null ? (
                                        <ul className="py-1">
                                            <hr className="border-t border-solid border-gray-300" />

                                            <li className="flex justify-start items-center relative text-left w-full box-border">
                                                <a href="https://unjected.com/#verified" target="_blank" rel="noopener noreferrer" className="flex flex-1 justify-start items-center relative text-left box-border">
                                                    <div className="px-4 text-opacity-54 inline-flex text-gray-700">
                                                        <MdOutlineVerifiedUser className="h-6 w-6" />
                                                    </div>

                                                    <div className="flex-1 mt-4 mb-4 px-4">
                                                        <span className="font-normal text-base leading-6 tracking-tight block text-gray-700">Memberships</span>
                                                    </div>
                                                </a>
                                            </li>

                                            <li className="flex justify-start items-center relative text-left w-full box-border">
                                                <a href="/connections" className="flex flex-1 justify-start items-center relative text-left box-border">
                                                    <div className="px-4 text-opacity-54 inline-flex text-gray-700">
                                                        <FaRegHeart className="h-6 w-6" />
                                                    </div>

                                                    <div className="flex-1 mt-4 mb-4 px-4">
                                                        <span className="font-normal text-base leading-6 tracking-tight block text-gray-700">Connections</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="flex justify-start items-center relative text-left w-full box-border">
                                                <a href="/messages" className="flex flex-1 justify-start items-center relative text-left box-border">
                                                    <div className="px-4 text-opacity-54 inline-flex text-gray-700">
                                                        <MdMessage className="h-6 w-6" />
                                                    </div>

                                                    <div className="flex-1 mt-4 mb-4 px-4">
                                                        <span className="font-normal text-base leading-6 tracking-tight block text-gray-700">Messages</span>
                                                    </div>
                                                </a>
                                            </li>

                                            <li className="flex justify-start items-center relative text-left w-full box-border">
                                                <a href="/profile" className="flex flex-1 justify-start items-center relative text-left box-border">
                                                    <div className="px-4 text-opacity-54 inline-flex text-gray-700">
                                                        <CgProfile className="h-6 w-6" />
                                                    </div>

                                                    <div className="flex-1 mt-4 mb-4 px-4">
                                                        <span className="font-normal text-base leading-6 tracking-tight block text-gray-700">Profile</span>
                                                    </div>
                                                </a>
                                            </li>

                                            <li className="flex justify-start items-center relative text-left w-full box-border">
                                                <a href="/settings" className="flex flex-1 justify-start items-center relative text-left box-border">
                                                    <div className="px-4 text-opacity-54 inline-flex text-gray-700">
                                                        <MdOutlineSettings className="h-6 w-6" />
                                                    </div>

                                                    <div className="flex-1 mt-4 mb-4 px-4">
                                                        <span className="font-normal text-base leading-6 tracking-tight block text-gray-700">Settings</span>
                                                    </div>
                                                </a>
                                            </li>

                                            <hr className="border-t border-solid border-gray-300" />

                                            <li className="bg-teal-200 bg-opacity-10 flex justify-start items-center relative text-left w-full box-border">
                                                <a href="#" onClick={handleLogout} rel="noopener noreferrer" className="flex flex-1 justify-start items-center relative text-left box-border">
                                                    <div className="px-4 text-opacity-54 inline-flex text-gray-700">
                                                        <FaSignOutAlt className="h-6 w-6" />
                                                    </div>
                                                    <div className="flex-1 mt-4 mb-4 px-4">
                                                        <span className="font-normal text-base leading-6 tracking-tight block text-gray-700">LogOut</span>
                                                    </div>
                                                </a>
                                            </li>
                                            

                                        </ul>

                                    ) : (

                                        <ul className="py-1">



                                            <li className="flex justify-start items-center relative text-left w-full box-border">
                                                <a href="/connections" className="flex flex-1 justify-start items-center relative text-left box-border">
                                                    <div className="px-4 text-opacity-54 inline-flex text-gray-700">
                                                        <FaRegHeart className="h-6 w-6" />
                                                    </div>

                                                    <div className="flex-1 mt-4 mb-4 px-4">
                                                        <span className="font-normal text-base leading-6 tracking-tight block text-gray-700">Connections</span>
                                                    </div>
                                                </a>
                                            </li>

                                            <li className="flex justify-start items-center relative text-left w-full box-border">
                                                <a href="/messages" className="flex flex-1 justify-start items-center relative text-left box-border">
                                                    <div className="px-4 text-opacity-54 inline-flex text-gray-700">
                                                        <MdMessage className="h-6 w-6" />
                                                    </div>

                                                    <div className="flex-1 mt-4 mb-4 px-4">
                                                        <span className="font-normal text-base leading-6 tracking-tight block text-gray-700">Messages</span>
                                                    </div>
                                                </a>
                                            </li>

                                            <li className="flex justify-start items-center relative text-left w-full box-border">
                                                <a href="/profile" className="flex flex-1 justify-start items-center relative text-left box-border">
                                                    <div className="px-4 text-opacity-54 inline-flex text-gray-700">
                                                        <CgProfile className="h-6 w-6" />
                                                    </div>

                                                    <div className="flex-1 mt-4 mb-4 px-4">
                                                        <span className="font-normal text-base leading-6 tracking-tight block text-gray-700">Profile</span>
                                                    </div>
                                                </a>
                                            </li>

                                            <li className="flex justify-start items-center relative text-left w-full box-border">
                                                <a href="/settings" className="flex flex-1 justify-start items-center relative text-left box-border">
                                                    <div className="px-4 text-opacity-54 inline-flex text-gray-700">
                                                        <MdOutlineSettings className="h-6 w-6" />
                                                    </div>

                                                    <div className="flex-1 mt-4 mb-4 px-4">
                                                        <span className="font-normal text-base leading-6 tracking-tight block text-gray-700">Settings</span>
                                                    </div>
                                                </a>
                                            </li>

                                            <hr className="border-t border-solid border-gray-300" />

                                            <li className="bg-teal-200 bg-opacity-10 flex justify-start items-center relative text-left w-full box-border">
                                                <a href="#" onClick={handleLogout} rel="noopener noreferrer" className="flex flex-1 justify-start items-center relative text-left box-border">
                                                    <div className="px-4 text-opacity-54 inline-flex text-gray-700">
                                                        <FaSignOutAlt className="h-6 w-6" />
                                                    </div>
                                                    <div className="flex-1 mt-4 mb-4 px-4">
                                                        <span className="font-normal text-base leading-6 tracking-tight block text-gray-700">LogOut</span>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    )
                                )}

                            </nav>
                        </ul>

                    </div>
                )}

            </div>
        </div>
    )
}
export default Header


