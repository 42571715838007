import React, { useState, useEffect } from 'react';
import Header from "../components/Header"
import Footer from "../components/Footer"
import { RiInboxUnarchiveLine } from "react-icons/ri";
import { BiSolidMessageAltAdd } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { IoMdSend } from "react-icons/io";
import { useNavigate, useLocation } from 'react-router-dom';
import { RiSkipLeftLine, RiSkipRightLine } from "react-icons/ri";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { ImUser } from "react-icons/im"
import { useUser } from '../contexts/UserContext';
import { FaBars } from "react-icons/fa";
import { FaTrashRestore } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Snackbar from '../components/Snackbar';
import {
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEModalFooter,
    TERipple,
} from "tw-elements-react";


const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
};

export default function Message() {

    const navigate = useNavigate();
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedTabFromQuery = queryParams.get('selectedTab');
    const [selectedTab, setSelectedTab] = useState(selectedTabFromQuery || 'Inbox');
    const [APICategory, setAPICategory] = useState('main');
    const [selectedUser, setSelectedUser] = useState(null);
    const [userData, setUserData] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const { user, logout } = useUser();
    const [loading, setLoading] = useState(false);


    const AccessToken = user ? user.cognito?.AuthenticationResult?.AccessToken : null;
    const user_id = user ? user.id : null;
    const userDeactivated = user ? user.deactivated : null;



    const boxCategory = selectedTab === "Request" ? "requests" :
        selectedTab === "Trash" ? "deleted" :
            "main";


    const tabs = [
        { tabName: 'Inbox', tabImage: <RiInboxUnarchiveLine size={20} /> },
        { tabName: 'Request', tabImage: <BiSolidMessageAltAdd size={20} /> },
        { tabName: 'Trash', tabImage: <IoTrashOutline size={20} /> },
    ];


    useEffect(() => {

        const fetchInitialData = async () => {
            if (AccessToken) {


                try {
                    const unreadCountsData = {};

                    // Fetch data for each box
                    const boxes = ['main', 'requests', 'deleted'];

                    for (const box of boxes) {
                        const apiParam = {
                            id: user_id,
                            box: box,
                            AccessToken: AccessToken
                        };

                        const response = await axios.post(`${REACT_APP_BASE_API_URL}/messages/inbox`, apiParam);
                        const count = response.data.data.data.reduce((acc, user) => {
                            if (user.participant_unread_count !== null) {
                                return acc + 1;
                            }
                            return acc;
                        }, 0);

                        // Set the unread count for each box
                        unreadCountsData[box] = count;
                    }
                    //console.log(unreadCountsData);

                    // Set the unread counts for all boxes
                    setUnreadCount(unreadCountsData);
                } catch (err) {
                    console.log(err);
                    if (err.response?.data?.data?.message.includes("Auth failed")) {
                        logout();
                        navigate("/signin?sessionExpired=true");
                    }
                }
            }
        };

        fetchInitialData();

    }, [user, AccessToken]);

    useEffect(() => {

        const conn = {
            id: user_id,
            box: boxCategory,
            AccessToken: AccessToken
        };
        //console.log("cahnged to " + selectedTab);
        if (AccessToken) {
            setLoading(true);
            axios.post(`${REACT_APP_BASE_API_URL}/messages/inbox`, conn)
                .then((response) => {
                    //console.log(response.data)
                    setUserData(response.data.data.data);
                    setLoading(false);
                    // Count the number of users with non-null participant_unread_count
                    const count = response.data.data.data.reduce((acc, user) => {
                        if (user.participant_unread_count !== null) {
                            return acc + 1;
                        }
                        return acc;
                    }, 0);


                    setUnreadCount((prevCounts) => ({
                        ...prevCounts,
                        [selectedTab]: count,
                    }));

                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                    // Show error Snackbar
                    setShowErrorSnackbar(true);
                    // Set the error message
                    setErrorSnackbarMessage(err.message || 'No users available.');
                    if (err.response?.data?.data?.message.includes("Auth failed")) {
                        logout();
                        navigate("/signin?sessionExpired=true");
                    }

                })
        }
    }, [selectedTab, AccessToken]);


    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);

        if (tabName === "Request") {
            setAPICategory("requests");
        } else if (tabName === "Trash") {
            setAPICategory("deleted");
        } else {
            setAPICategory("main");
        }
    };



    const handleToggleMobileMenu = () => {
        setMobileMenuVisible(!mobileMenuVisible);
    };

    const handleUserClick = (user) => {
        //const { starter_user_id, participant_user_id } = user
        setSelectedUser(user);
        navigate('/userchat', { state: { user } });
        //navigate('/view-profile', { state: { userData: modalData } });
    };


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(userData.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = userData.slice(indexOfFirstItem, indexOfLastItem);

    const handleClickNext = () => {
        setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
    };

    const handleClickPrev = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };

    const handleClickFirst = () => {
        setCurrentPage(1);
    };

    const handleClickLast = () => {
        setCurrentPage(totalPages);
    };

    const modalOpen = (conversation_id) => {
        setShowModal(true);
        const id = conversation_id;
        setModalData(id);

    }

    const handleDelete = () => {


        if (modalData) {
            axios.post(`${REACT_APP_BASE_API_URL}/messages/delete`, {
                conversation_id: modalData,
                deleted: selectedTab === 'Trash' ? false : true,
                AccessToken: AccessToken
            })
                .then((response) => {
                    const decryptedResponse = decryptData(response.data, process.env.REACT_APP_ENCRYPTION_KEY);

                    // Now you can use the decrypted response

                    setUserData(prevUserData => {
                        // Filter out the deleted user from the current userData array
                        const updatedUserData = prevUserData.filter(user => user.conversation_id !== modalData);
                        return updatedUserData;
                    });

                    setShowModal(false);
                })
                .catch((err) => {
                    console.log(err);
                    setShowErrorSnackbar(true);
                    setErrorSnackbarMessage(err.message || 'User not deleted.');
                });
        }

    };

    return (
        <>
            <Header className="fixed top-0 left-0 w-full" />
            <div className="bg-connectionBg bg-cover">
                <main className="container mx-auto px-2 py-11 sm:px-6 lg:p-11 w-full max-w-md lg:max-w-[70%] rounded">
                    <div className="flex flex-col flex-wrap">
                        <div className="ml-4 mt-4">
                            <h5 className="text-black text-opacity-80 text-2xl">Message</h5>
                        </div>
                        <Snackbar
                            message={showSuccessSnackbar.message}
                            type="success"
                            showSnackbar={showSuccessSnackbar}
                            setShowSnackbar={setShowSuccessSnackbar}
                        />
                        <Snackbar
                            message={errorSnackbarMessage}
                            type="error"
                            showSnackbar={showErrorSnackbar}
                            setShowSnackbar={setShowErrorSnackbar}
                        />
                        <br></br>
                        {userDeactivated ? (
                            <div className="text-center mt-5 bg-red-100 border border-red-400 text-red-700 p-4 rounded">
                                <p>You are not allowed to access this page because your account is deactivated.</p>
                            </div>

                        ) : (
                            <div className="mb-5">

                                <div className="mt-4 flex flex-col p-6 rounded-3xl shadow-custom min-h-80">

                                    {/* Mobile Menu Button */}
                                    <div className="lg:hidden">
                                        <button
                                            onClick={handleToggleMobileMenu}
                                            className="text-black focus:outline-none"
                                        >
                                            <FaBars className="h-5 w-5" />
                                        </button>
                                    </div>

                                    {/* Mobile Menu */}
                                    <div className={`lg:hidden ${mobileMenuVisible ? 'block' : 'hidden'}`}>
                                        <div className="bg-white shadow-custom rounded-md w-[70%] p-2">
                                            {tabs.map((tab, index) => (
                                                <><ul
                                                    key={tab.tabName}
                                                    className={`hover:border-hoverTab hover:bg-hoverTab group flex-1 transform ${selectedTab === tab.tabName ? 'bg-borderLogin' : ''}`}
                                                    onClick={() => {
                                                        handleTabClick(tab.tabName);
                                                        handleToggleMobileMenu();
                                                    }}
                                                >
                                                    <li className="m-2 -transform bg-transparent px-7 text-center text-borderLogin first-letter:uppercase flex items-center flex-1 justify-start">
                                                        <span
                                                            className={`mr-2 ${selectedTab === tab.tabName ? 'group-hover:text-white text-white' : 'group-hover:text-white'}`}
                                                        >
                                                            {tab.tabImage}
                                                        </span>
                                                        <b className={`block text-md group-hover:text-white ${selectedTab === tab.tabName ? 'text-white' : 'text-borderLogin'} mr-4`}>
                                                            {tab.tabName}
                                                        </b>

                                                        {index === 0 && unreadCount.main > 0 && (
                                                            <div className="bg-borderLogin border border-white p-2 text-white text-xs rounded-full w-3 h-3 flex items-center justify-center">{unreadCount.main}</div>
                                                        )}
                                                        {index === 1 && unreadCount.requests > 0 && (
                                                            <div className="bg-borderLogin border border-white p-2 text-white text-xs rounded-full w-3 h-3 flex items-center justify-center">{unreadCount.requests}</div>
                                                        )}
                                                        {index === 2 && unreadCount.deleted > 0 && (
                                                            <div className="bg-borderLogin border border-white p-2 text-white text-xs rounded-full w-3 h-3 flex items-center justify-center">{unreadCount.deleted}</div>
                                                        )}
                                                    </li>
                                                </ul>
                                                    {index < tabs.length - 1 && <hr className="border-t border-solid border-white-300" />}

                                                </>
                                            ))}

                                        </div>
                                    </div>

                                    <div className="mt-4 flex">
                                        <div className="hidden h-20 lg:flex lg:flex-col gap-1 ml-1 mr-1">
                                            {tabs.map((tab, index) => (
                                                <ul
                                                    key={tab.tabName}
                                                    className={`hover:border-hoverTab hover:bg-hoverTab group flex-1 transform border border-b ${selectedTab === tab.tabName ? 'bg-borderLogin' : ''}`}
                                                    onClick={() => handleTabClick(tab.tabName)}
                                                >
                                                    <li className="m-4 -transform bg-transparent px-7 text-center text-borderLogin first-letter:uppercase flex items-center flex-1 justify-start">

                                                        <span
                                                            className={`mr-2 ${selectedTab === tab.tabName ? 'group-hover:text-white text-white' : 'group-hover:text-white'}`}
                                                        >
                                                            {tab.tabImage}
                                                        </span>
                                                        <b className={`block text-lg group-hover:text-white ${selectedTab === tab.tabName ? 'text-white' : 'text-borderLogin'} mr-4`}>{tab.tabName}</b>

                                                        {/* Display the badge with count */}

                                                        {index === 0 && unreadCount.main > 0 && (
                                                            <div className="bg-borderLogin border border-white p-3 text-white text-sm rounded-full w-4 h-4 flex items-center justify-center">{unreadCount.main}</div>
                                                        )}
                                                        {index === 1 && unreadCount.requests > 0 && (
                                                            <div className="bg-borderLogin border border-white p-3 text-white text-sm rounded-full w-4 h-4 flex items-center justify-center">{unreadCount.requests}</div>
                                                        )}
                                                        {index === 2 && unreadCount.deleted > 0 && (
                                                            <div className="bg-borderLogin border border-white p-3 text-white text-sm rounded-full w-4 h-4 flex items-center justify-center">{unreadCount.deleted}</div>
                                                        )}


                                                    </li>


                                                </ul>
                                            ))}
                                        </div>

                                        <div className="ml-4 flex flex-col w-full h-full">
                                            <div>
                                                {loading ? (
                                                    <div className='py-14 flex justify-center items-center h-full'>
                                                        <div
                                                            className="float-center inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                                            role="status">
                                                            <span
                                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                                            >Loading...</span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {currentItems.length > 0 ? (
                                                            currentItems.map((user, index) => (
                                                                <div>
                                                                    <div key={user.conversation_id} className={`w-full flex hover:bg-gray-100 ${selectedUser === index ? 'bg-blue-200' : ''}`}>
                                                                        <div className="w-full flex items-center" onClick={() => handleUserClick(user)}>

                                                                            {(user_id == user.starter_user_id) ? (
                                                                                // Display participant image
                                                                                <div className="relative">
                                                                                    {user.participant_unread_count !== null && user.participant_unread_count > 0 && (
                                                                                        <div className="absolute top-0 right-0 bg-borderLogin border border-white text-white text-sm rounded-full w-4 h-4 flex items-center justify-center">
                                                                                            {user.participant_unread_count}
                                                                                        </div>
                                                                                    )}
                                                                                    {user.participant_user_image_path ? (
                                                                                        <img
                                                                                            src={`https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${user.participant_user_image_path}`}
                                                                                            alt=""
                                                                                            className="w-10 h-10 rounded-md mr-4"
                                                                                        />
                                                                                    ) : (
                                                                                        <div className="flex items-center justify-center">
                                                                                            <ImUser className="w-10 h-10 rounded-md mr-4" />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                // Display starter image or default image
                                                                                <div className="relative">
                                                                                    {user.participant_unread_count !== null && user.participant_unread_count > 0 && (
                                                                                        <div className="absolute top-0 right-0 bg-borderLogin border border-white text-white text-sm rounded-full w-4 h-4 flex items-center justify-center">
                                                                                            {user.participant_unread_count}
                                                                                        </div>
                                                                                    )}
                                                                                    {user.starter_user_image_path ? (
                                                                                        <img
                                                                                            src={`https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${user.starter_user_image_path}`}
                                                                                            alt=""
                                                                                            className="w-10 h-10 rounded-md mr-4"
                                                                                        />
                                                                                    ) : (
                                                                                        <div className="flex items-center justify-center">
                                                                                            <ImUser className="w-10 h-10 rounded-md mr-4" />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                            }


                                                                            <div className="flex items-center justify-between w-full">

                                                                                <div>
                                                                                    <p>
                                                                                        {
                                                                                            (user_id == user.starter_user_id) ?
                                                                                                (`${user.participant_first_name || ''} ${user.participant_last_name || ''} (${user.participant_username || ''})`)
                                                                                                : (`${user.starter_first_name || ''} ${user.starter_last_name || ''} (${user.starter_username || ''})`)
                                                                                        }

                                                                                    </p>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <button
                                                                                onClick={() => modalOpen(user.conversation_id)}
                                                                                className="p-2 text-borderLogin ml-auto hover:text-red-500"
                                                                            >
                                                                                {selectedTab === 'Trash' ? <FaTrashRestore /> : <MdDelete />}
                                                                            </button>
                                                                            {showModal && (
                                                                                <TEModal show={showModal} setShow={setShowModal}>
                                                                                    <TEModalDialog className="p-5 sm:max-w-[50%] md:max-w-[50%] lg:max-w-[50%] xl:max-w-[65%]">
                                                                                        <TEModalContent>
                                                                                            <TEModalHeader>

                                                                                                <h2 className="text-xl font-medium items-center justify-center leading-normal text-neutral-800 dark:text-neutral-200">
                                                                                                    Delete user
                                                                                                </h2>

                                                                                            </TEModalHeader>
                                                                                            <TEModalBody className="h-auto">


                                                                                                <div className="w-full flex flex-wrap items-center justify-between">
                                                                                                    <div className="flex flex-wrap m-2">
                                                                                                        <h6 className="font-semibold text-base">
                                                                                                            {selectedTab === 'Trash' ? "Are you sure you want to restore this user?" : "Are you sure you want to delete this user?"}

                                                                                                        </h6>

                                                                                                        <br></br>

                                                                                                    </div>

                                                                                                </div>

                                                                                            </TEModalBody>
                                                                                            <TEModalFooter className="flex">

                                                                                                <TERipple rippleColor="light">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                                                                                        onClick={() => {
                                                                                                            setShowModal(false);

                                                                                                        }}
                                                                                                    >
                                                                                                        No
                                                                                                    </button>
                                                                                                </TERipple>

                                                                                                <TERipple rippleColor="light">

                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                                                                                                        onClick={() => handleDelete()}
                                                                                                    >
                                                                                                        Yes
                                                                                                    </button>

                                                                                                </TERipple>


                                                                                            </TEModalFooter>
                                                                                        </TEModalContent>
                                                                                    </TEModalDialog>

                                                                                </TEModal>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {index < currentItems.length - 1 && <hr className="border-t border-solid border-gray-300 mt-2 mb-2" />}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p className='text-gray-400 text-sm text-center py-20'>No users available.</p>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="py-3">
                                                <nav>
                                                    <ul className="flex flex-wrap items-center">
                                                        <li className="m-1">
                                                            <button className={`flex items-center justify-center border-2 border-solid border-gray-300 rounded ${currentPage === 1 ? 'opacity-50 disabled cursor-not-allowed' : ''}`}
                                                                onClick={handleClickFirst}
                                                            >
                                                                <RiSkipLeftLine className="h-5 w-5 m-4" />
                                                            </button>
                                                        </li>
                                                        <li className="m-1">
                                                            <button className={`flex items-center justify-center border-2 border-solid border-gray-300 rounded ${currentPage === 1 ? 'opacity-50 disabled cursor-not-allowed' : ''}`}
                                                                onClick={handleClickPrev}
                                                            >
                                                                <GoArrowLeft className={`h-5 w-5 m-4 currentPage === 1 ? 'opacity-50' : ''`} />
                                                            </button>
                                                        </li>
                                                        <li className="m-1">
                                                            <div className="px-4 flex items-center justify-center">
                                                                ...
                                                            </div>
                                                        </li>
                                                        <li className="m-1">
                                                            <button className={`flex items-center justify-center border-2 border-solid border-gray-300 rounded ${currentPage === totalPages ? 'opacity-50 disabled cursor-not-allowed' : ''}`}
                                                                onClick={handleClickNext}>
                                                                <GoArrowRight className="h-5 w-5 m-4" />
                                                            </button>
                                                        </li>
                                                        <li className="m-1">
                                                            <button className={`flex items-center justify-center border-2 border-solid border-gray-300 rounded ${currentPage === totalPages ? 'opacity-50 disabled cursor-not-allowed' : ''}`}
                                                                onClick={handleClickLast}>
                                                                <RiSkipRightLine className="h-5 w-5 m-4" />
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>


                                    </div>



                                </div>
                            </div>
                        )}
                    </div>
                </main>
            </div>
            <Footer />
        </>
    )
}