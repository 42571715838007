import React, { useState } from 'react';
import Footer from "../components/Footer";
import Header from "../components/Header"

export default function notFound() {
    

    return (
        <>
            <Header className="fixed top-0 left-0 w-full" />
            <div className="bg-hero-pattern bg-cover">
                <main className="container mx-auto px-2 py-11 sm:px-6 lg:p-11 w-full max-w-md lg:max-w-[70%] rounded">
                    <div className="flex flex-col flex-wrap h-full">
                        <div className="ml-4 mt-4 h-[600px]">
                            <h5 className="text-black text-opacity-80 text-2xl">The page you are looking for cannot be found</h5>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </>
    );
}