import React, { useState, useEffect } from 'react';
import Header from "../components/Header"
import Footer from "../components/Footer"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { differenceInYears } from 'date-fns';
import { MdEmail } from "react-icons/md";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { ImUser } from "react-icons/im"
import Snackbar from '../components/Snackbar';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { Carousel, initTE } from "tw-elements";
import { useUser } from '../contexts/UserContext';
import {
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEModalFooter,
    TERipple,
} from "tw-elements-react";
initTE({ Carousel });
const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;


const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
};
export default function viewProfile() {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [userData, setUserData] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const location = useLocation();
    const { user, logout } = useUser();
    //const AccessToken = user ? user.cognito?.AuthenticationResult?.AccessToken : null;

    const [AccessToken, setAccessToken] = useState(null);
    const { username } = useParams();
    const navigate = useNavigate();
   // const AccessToken = user ? user.cognito?.AuthenticationResult?.AccessToken : null;

    const loginId = user ? user.id : null;
    const userDeactivated = user ? user.deactivated : null;


    let modalData = null;

    if (location.state && location.state.userData) {
        modalData = location.state?.userData || null;
    } else {
        modalData = location.state?.userParticipant || null;

      
    }
    //const modalData = location.state?.userData || null;
    //console.log(modalData);

    //login user
    const permissionNames = user ? user.permission_names : null;
    const loginUserPermission = permissionNames?.split(',').map(permission => permission.trim());
    const loginHasPermission = loginUserPermission?.includes('user-connect-content') || loginUserPermission?.includes('user-verified-content') || loginUserPermission?.includes('user-premium-content') || loginUserPermission?.includes('c') || loginUserPermission?.includes('v') || loginUserPermission?.includes('p');

    //console.log("Login:",permissionNames);

    //connection user
    const connectionUserPermisssion = userData ? userData.permission_names : null;
    const userPermission = connectionUserPermisssion?.split(',').map(permission => permission.trim());
    //console.log("user:",connectionUserPermisssion);


    useEffect(() => {
       
        if (modalData && username) {
            axios.post(`${REACT_APP_BASE_API_URL}/users/profile/main-name`, {
                username: username,
            }).then((response) => {
                const decryptedData = decryptData(response.data, process.env.REACT_APP_ENCRYPTION_KEY);
              
                // Retrieve interests array
                const interestsArray = decryptedData.data.data.interests;
                const languagesArray = decryptedData.data.data.languages;
                // Extract title value and join with comma(,)
                const interestsString = interestsArray.map(interest => interest.title).join(', ');
                const languagesString = languagesArray.map(language => language.title).join(', ');

                // Set the interest data with real API data in the state
                setUserData({ ...decryptedData.data.data, interests: interestsString, languages: languagesString });
            })
                .catch((err) => {
                    if (err.response && err.response.status === 400) {
                       // navigate('/*');
                        //history.push('/not-found'); // Change '/not-found' to your actual route path for the NotFound component
                    } else {
                        setShowErrorSnackbar(true);
                    }
                })
        }
        else{
            navigate('/*'); 
        }
    }, [user]);

    useEffect(() => {
        if (user && AccessToken === null) {
            const accessToken = user.cognito?.AuthenticationResult?.AccessToken;
            setAccessToken(accessToken);
        }
        let userId = null;
        if (location.state && location.state.userData) {
            userId = modalData ? modalData.id : null;
            //console.log(userId);
        } else {
            if (modalData) {
                if (loginId == modalData.starter_user_id) {
                    userId = modalData ? (modalData.participant_user_id || modalData.id) : null;
                } else {
                    userId = modalData ? (modalData.starter_user_id || modalData.id) : null;
                }
            }

            //console.log("connect", userId);
        }
        //const userId = modalData ? modalData.id : null
       
        if (AccessToken && userId) {
           
            axios.post(`${REACT_APP_BASE_API_URL}/users/profile/main`, {
                userId: userId,
                AccessToken: AccessToken
            }).then((response) => {
                const decryptedResponse = decryptData(response.data, process.env.REACT_APP_ENCRYPTION_KEY);

                // Retrieve interests and languages arrays
                const interestsArray = decryptedResponse.data.data.interests || [];
                const languagesArray = decryptedResponse.data.data.languages || [];

                // Extract title value and join with comma(,)
                const interestsString = interestsArray.map(interest => interest.title).join(', ');
                const languagesString = languagesArray.map(language => language.title).join(', ');

                // Set the interest data with related API data in the state
                setUserData({ ...decryptedResponse.data.data, interests: interestsString, languages: languagesString });


            })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [location.state]);

    const handlePrevious = () => {
        const prevSlide = (currentSlide - 1 + userData.images.length) % userData.images.length;
        setCurrentSlide(prevSlide);
    };

    const handleNext = () => {
        const nextSlide = (currentSlide + 1) % userData.images.length;
        setCurrentSlide(nextSlide);
    };

    const connectMessage = () => {
        if ((!loginUserPermission) || (!loginHasPermission)) {
            // Show modal for login user with no permissions
            setShowModal(true);
        } else {
            const loginHasConnectPermission = loginUserPermission?.includes('user-connect-content') || loginUserPermission?.includes('c');
            const loginHasPremiumPermission = loginUserPermission?.includes('user-premium-content') || loginUserPermission?.includes('user-connect-content') || loginUserPermission?.includes('p') || loginUserPermission?.includes('c');
            const loginHasVerifiedPermission = loginUserPermission?.includes('user-connect-content') || loginUserPermission?.includes('user-verified-content') || loginUserPermission?.includes('user-premium-content') || loginUserPermission?.includes('c') || loginUserPermission?.includes('v') || loginUserPermission?.includes('p');

            const hasConnectPermission = userPermission?.includes('user-connect-content') || userPermission?.includes('c');
            const hasPremiumPermission = userPermission?.includes('user-premium-content') || userPermission?.includes('user-connect-content') || userPermission?.includes('p') || userPermission?.includes('c');
            const hasVerifiedPermission = userPermission?.includes('user-connect-content') || userPermission?.includes('user-verified-content') || userPermission?.includes('user-premium-content') || userPermission?.includes('c') || userPermission?.includes('v') || userPermission?.includes('p');

            if ((loginUserPermission && !hasVerifiedPermission) || (loginUserPermission && !userPermission)) {
                navigate('/userchat', { state: { userData } });
                //console.log("No permissions for connection user");
            } else {
                if ((loginHasConnectPermission && hasConnectPermission) || (loginHasPremiumPermission && hasPremiumPermission) || (loginHasVerifiedPermission && hasVerifiedPermission)) {
                    navigate('/userchat', { state: { userData } });
                    //console.log("With permission");
                } else {
                    // if login user have level 0 permission and connection user have any permission excluding level 0
                    setShowModal(true);
                    //console.log("With permission1");
                }
            }
        }
    };

    return (
        <>
            <Header className="fixed top-0 left-0 w-full" />
            <div className="bg-connectionBg bg-cover">
                <main className="container mx-auto px-2 py-11 sm:px-6 lg:p-11 w-full max-w-md lg:max-w-[70%] rounded">
                    <div className="flex flex-col">
                        <div className="ml-4 mt-4">
                            <h5 className="text-black text-opacity-80 text-2xl">Profile</h5>
                        </div>

                        <Snackbar
                            message={""}
                            type="success"
                            showSnackbar={showSuccessSnackbar}
                            setShowSnackbar={setShowSuccessSnackbar}
                        />
                        <Snackbar
                            message={"Profile not found"}
                            type="error"
                            showSnackbar={showErrorSnackbar}
                            setShowSnackbar={setShowErrorSnackbar}
                        />
                        <br></br>

                        {userDeactivated || (userData && userData.deactivated) ? (
                            <div className="text-center mt-5 bg-red-100 border border-red-400 text-red-700 p-4 rounded">
                                <p>{userData && userData.deactivated ? 'This user profile is no longer available.' : 'You are not allowed to access this page because your account is deactivated.'}</p>
                            </div>
                        ) : (
                            <div className="mb-5">

                                <div className="mt-4 flex flex-col p-6 rounded-3xl shadow-custom">

                                    <div className="w-full flex flex-wrap items-center justify-between align-top p-1">

                                        <h5 className="text-xl flex-grow text-center font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                                            {userData && userData.username ? userData.username : '-'}
                                        </h5>

                                        <button
                                            type="button"
                                            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                            aria-label="Next"
                                            onClick={connectMessage}
                                        >
                                            <MdEmail className="h-6 w-6" />

                                        </button>
                                        {showModal && (
                                            <TEModal show={showModal} setShow={setShowModal}>
                                                <TEModalDialog className="p-5 sm:max-w-[50%] md:max-w-[50%] lg:max-w-[50%] xl:max-w-[65%]">
                                                    <TEModalContent>
                                                        <TEModalHeader>

                                                            <h4 className="text-sm items-center justify-center leading-normal text-neutral-600 dark:text-neutral-400">
                                                                We are so grateful for you! At this time we are asking that everyone supports our mission by choosing a membership. This will give you full access to Unjected to keep connecting!
                                                                Use the code OG for 25% off your first month.
                                                                Thank you for being here and being Unjected
                                                            </h4>

                                                        </TEModalHeader>
                                                        <TEModalBody className="h-auto">


                                                            <div className="w-full flex flex-wrap items-center justify-between">
                                                                <div className="flex flex-wrap m-2  item-center">
                                                                    <h6 className="font-semibold text-base">
                                                                        Match and message with members for dating, community and frendships
                                                                        <br>
                                                                        </br>
                                                                        Go here to select a membership:

                                                                    </h6>
                                                                </div>
                                                                <div className="text-base">
                                                                </div>
                                                                <div className="w-full flex item-center justify-center">
                                                                    <TERipple rippleColor="light">

                                                                        <button
                                                                            type="button"
                                                                            className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                                                                            onClick={() => window.open('https://unjected.com/pages/unjected-memberships', '_blank')}
                                                                        >
                                                                            Join Now
                                                                        </button>

                                                                    </TERipple>
                                                                </div>
                                                            </div>

                                                        </TEModalBody>
                                                        <TEModalFooter className="flex justify-between">

                                                            <TERipple rippleColor="light">
                                                                <button
                                                                    type="button"
                                                                    className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                                                    onClick={() => {
                                                                        setShowModal(false);
                                                                        setCurrentSlide(0);
                                                                    }}
                                                                >
                                                                    Close
                                                                </button>
                                                            </TERipple>



                                                        </TEModalFooter>
                                                    </TEModalContent>
                                                </TEModalDialog>

                                            </TEModal>
                                        )}

                                    </div>

                                    <div className="w-full">
                                        <hr className="border-t border-solid border-gray-300 m-2" />
                                    </div>

                                    <div className="w-full flex flex-col align-top p-4">
                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">

                                                    Name

                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData && userData.first_name ? userData.first_name : '-'} {userData && userData.last_name ? userData.last_name : '-'}{userData.birthday && `, ${differenceInYears(new Date(), new Date(userData.birthday))}`}

                                                </p>
                                            </div>
                                        </div>

                                        {/* <div className="flex">
                                                  <div className="m-2">
                                                      <h6 className="font-semibold text-base">
                                            
                                                          Email
                                                    
                                                      </h6>
                                                  </div>
                                                  <div className="m-2">
                                                      <p className="text-gray-500 text-base font-normal">
                                                        {userData && userData.email ? userData.email : 'Null'}
                                                      </p>
                                                  </div>
                                                </div>      */}
                                    </div>



                                    <div className="relative"
                                        data-te-carousel-init
                                        data-te-ride="carousel"
                                        id="carouselExampleControls">

                                        <div className="flex w-full h-80 bg-gray-300 items-center text-center justify-center rounded-md overflow-hidden after:clear-both after:block after:content-['']">

                                            {userData?.images && userData.images.length > 0 ? (
                                                userData.images.map((imagePath, index) => (
                                                    <div
                                                        key={index}
                                                        className={`relative float-left w-full h-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none ${index === currentSlide ? 'block' : 'hidden'
                                                            }`}
                                                        data-te-carousel-item
                                                        data-te-carousel-active={index === currentSlide ? 'true' : 'false'}
                                                    >
                                                        <img
                                                            src={`https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${imagePath.path}`}
                                                            alt=""
                                                            className="rounded-md h-full w-full object-contain"
                                                        />
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="flex items-center justify-center">
                                                    <ImUser className="text-white w-40 h-40" />
                                                </div>
                                            )}
                                        </div>


                                        <button
                                            className="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-customBlue opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-customBlue hover:no-underline hover:opacity-90 hover:outline-none focus:text-customBlue focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                                            type="button"
                                            onClick={handlePrevious}

                                            disabled={currentSlide === 0}

                                            data-te-target="#carouselExampleControls"
                                            data-te-slide="prev">
                                            <span className="inline-block h-8 w-8">
                                                <GoChevronLeft className="h-8 w-8" />
                                            </span>
                                            <span
                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                            >Previous</span
                                            >
                                        </button>

                                        <button
                                            className="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-customBlue opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-customBlue hover:no-underline hover:opacity-90 hover:outline-none focus:text-customBlue focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                                            type="button"
                                            onClick={handleNext}
                                            data-te-target="#carouselExampleControls"
                                            data-te-slide="next">
                                            <span className="inline-block h-8 w-8">
                                                <GoChevronRight className="h-8 w-8" />
                                            </span>
                                            <span
                                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                            >Next</span
                                            >
                                        </button>

                                    </div>

                                    <div className="w-full flex flex-col align-top p-4">
                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">

                                                    Gender

                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData?.gender === 1 ? 'Female' : userData?.gender === 2 ? 'Male' : '-'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">
                                                    Location
                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData && userData.label ? userData.label : '-'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">

                                                    About me

                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData && userData.about_me ? userData.about_me : '-'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">

                                                    Interests

                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData && userData.interests ? userData.interests : '-'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">

                                                    Languages

                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData && userData.languages ? userData.languages : '-'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">

                                                    Looking For

                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData && userData.looking_for ? userData.looking_for : '-'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">

                                                    Profession

                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData && userData.profession ? userData.profession : '-'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">

                                                    Marital Status

                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData && userData.marital_status ? userData.marital_status : '-'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">

                                                    Do you have children?

                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData?.children === 1 ? 'Yes' : userData?.children === 2 ? 'No' : userData?.children === 3 ? 'Wants children someday' : userData?.children === 4 ? "Doesn't want children" : '-'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">

                                                    Starsign

                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData && userData.starsign ? userData.starsign : '-'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">

                                                    Instagram

                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData && userData.instagram ? userData.instagram : '-'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">

                                                    Twitter

                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData && userData.twitter ? userData.twitter : '-'}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="m-2">
                                                <h6 className="font-semibold text-base">

                                                    Website

                                                </h6>
                                            </div>
                                            <div className="m-2">
                                                <p className="text-gray-500 text-base font-normal">
                                                    {userData && userData.website ? userData.website : '-'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        )}
                    </div>
                </main>
            </div>
            <Footer />

        </>
    )
}