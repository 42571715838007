import React, { useState, useEffect } from 'react';
import Header from "../components/Header"
import Footer from "../components/Footer"
import { FaCheckCircle, } from "react-icons/fa"
import { object, string, ref } from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form"
import axios from 'axios';
import { useUser } from '../contexts/UserContext';
import connectM from "../assets/images/1.png";
import premiumM from "../assets/images/2.png";
import verifiedM from "../assets/images/3.png";
import Snackbar from '../components/Snackbar';
import { useNavigate } from 'react-router-dom';
const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const CryptoJS = require('crypto-js');

function decryptData(encryptedData, key) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, key);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}

export default function Settings() {
  const navigate = useNavigate();
  const { user, login, logout } = useUser();

  const cognito_id = user ? user.cognito_user_id : null;
  const email = user ? user.email : null;
  const birthday = user ? user.hidden_birthday : null;
  const accountStatus = user ? user.deactivated : null;
  const permissionNames = user ? user.permission_names : null;

  const [isActive, setIsActive] = useState(accountStatus);
  const [isBirthdayActive, setIsBirthdayActive] = useState(birthday);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

  //console.log(user);

  useEffect(() => {
    setIsBirthdayActive(birthday);
    setIsActive(accountStatus);

    if (user) {
      axios.post(`${REACT_APP_BASE_API_URL}/users/authorize`, { AccessToken: user.cognito?.AuthenticationResult?.AccessToken })
        .then((response) => {
          if (response.data.status === "success") {
            //console.log("success");
          } else {
            logout();
            navigate("/signin?sessionExpired=true")
          }
        })
        .catch((err) => {
          logout();
          navigate("/signin?sessionExpired=true");
        })
    }

  }, [user]);


  const handleBirthdayChange = () => {
    const newIsBirthdayActive = !isBirthdayActive;
    if (cognito_id) {
      axios
        .patch(`${REACT_APP_BASE_API_URL}/users/profile/hidden-birthday/${cognito_id}`, {
          hidden_birthday: newIsBirthdayActive,
          AccessToken: user.cognito.AuthenticationResult.AccessToken
        })
        .then((response) => {
          const decryptedResponse = decryptData(response.data, process.env.REACT_APP_ENCRYPTION_KEY);
          // Handle the decrypted response data here
          setShowSuccessSnackbar(true);
          window.scrollTo(0, 0);


          // Update the user context with the new hidden_birthday value
          login({
            ...user,
            hidden_birthday: newIsBirthdayActive,
          });

        })
        .catch((err) => {
          console.log(err);
          setShowErrorSnackbar(true);
          window.scrollTo(0, 0);

          if (err.response?.data?.data?.message.includes("Auth failed")) {
            logout();
            navigate("/signin?sessionExpired=true");
          }
        });
    }
  };

  const handleStatusUpdate = () => {

    const newAccountStatus = !isActive;
    if (cognito_id) {

      axios
        .patch(`${REACT_APP_BASE_API_URL}/users/profile/active-status/${cognito_id}`, {
          deactivated: newAccountStatus,
          AccessToken: user.cognito.AuthenticationResult.AccessToken
        })
        .then((response) => {
          //console.log(response.data);
          setShowSuccessSnackbar(true);
          window.scrollTo(0, 0);
          // Update the user context with the new hidden_birthday value
          login({
            ...user,
            deactivated: newAccountStatus,
          });

        })
        .catch((err) => {
          console.log(err);
          setShowErrorSnackbar(true);
          window.scrollTo(0, 0);

          if (err.response?.data?.data?.message.includes("Auth failed")) {
            logout();
            navigate("/signin?sessionExpired=true");
          }
        });
    }
  };

  const schema = object().shape({
    email: string().required("Email is required").email("Invalid email address"),
    password: string().required("Old password is required")
      .min(6, "The password must be 6 characters or more")
      .max(32, "The password must be 32 characters or less"),
    newPassword: string()
      .required("Password is required")
      .min(6, "The password must be 6 characters or more")
      .max(32, "The password must be 32 characters or less"),
    confirmPassword: string()
      .required("Please re-type your password")
      .oneOf([ref("newPassword")], "Passwords does not match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (formData) => {

    const { email, password, newPassword } = formData;

    const apiPayload = {
      email: email,
      currentPassword: password,
      newPassword: newPassword,
    };

    axios.post(`${REACT_APP_BASE_API_URL}/users/user/change`, apiPayload)
      .then((response) => {
        //console.log(response.data);
        // Handle success, e.g., show a success message or redirect
      })
      .catch((err) => {
        console.log(err);
        // Handle error, e.g., show an error message to the user
      });
  };

  return (
    <>
      <Header className="fixed top-0 left-0 w-full" />
      <div className="bg-connectionBg bg-cover">
        <main className="container mx-auto px-2 py-11 sm:px-6 lg:p-11 w-full max-w-md lg:max-w-[70%] rounded">
          <div className="flex flex-col flex-wrap">
            <div className="ml-4 mt-4">
              <h5 className="text-black text-opacity-80 text-2xl">Settings</h5>
            </div>
            <div className="w-full">
              <Snackbar
                message="Changed successfully!"
                type="success"
                showSnackbar={showSuccessSnackbar}
                setShowSnackbar={setShowSuccessSnackbar}
              />
              <Snackbar
                message={errorSnackbarMessage}
                type="error"
                showSnackbar={showErrorSnackbar}
                setShowSnackbar={setShowErrorSnackbar}
              />
            </div>
            <br></br>

            <div className="mb-5">

              <div className="mt-2 flex flex-col p-6 rounded-3xl shadow-custom">

                <div className="w-full flex flex-wrap items-center align-top p-1">

                  <div className="w-full flex flex-col">

                    <div className="m-1">

                      <h6 className="text-lg">

                        Email:

                      </h6>
                    </div>

                    <div className="flex flex-col relative w-full min-w-[200px]">
                      <input
                        {...register('email')}
                        name="email"
                        type="email"
                        value={email}
                        className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                        placeholder=" "
                        readOnly
                      />

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Email
                      </label>
                    </div>
                  </div>


                  <div className="w-full">
                    <hr className="border-t border-solid border-gray-300 mt-4 mb-4" />
                  </div>

                  <div className="w-full items-center p-1 mt-2">
                    <h5 className="text-black text-opacity-80 text-2xl">Change password</h5>
                  </div>

                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="flex h-11 flex-col relative w-full min-w-[200px] mt-4">
                      <input
                        {...register('password')}
                        name="password"
                        className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                        placeholder=" "

                      />

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Current password*
                      </label>
                      {errors.password && (
                        <p className="text-xs italic text-red-500">{errors.password.message}</p>
                      )}

                    </div>

                    <div className="flex h-11 flex-col relative w-full min-w-[200px] mt-4">
                      <input
                        {...register('newPassword')}
                        name="newPassword"
                        className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                        placeholder=" "

                      />

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        New password*
                      </label>
                      {errors.newPassword && (
                        <p className="text-xs italic text-red-500">{errors.newPassword.message}</p>
                      )}

                    </div>

                    <div className="flex h-11 flex-col relative w-full min-w-[200px] mt-4">
                      <input
                        {...register('confirmPassword')}
                        name="confirmPassword"
                        className="peer w-full rounded-[7px] border border-inputBorder border-t-transparent bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                        placeholder=" "

                      />

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                        Confirm password*
                      </label>
                      {errors.confirmPassword && (
                        <p className="text-xs italic text-red-500">{errors.confirmPassword.message}</p>
                      )}
                    </div>

                    <div className="mt-4 text-center">
                      <button
                        type="submit"
                        className="bg-borderLogin hover:bg-hoverButton rounded-full shadow-md text-white font-bold inline-block py-1 px-12"
                      >
                        CHANGE PASSWORD
                      </button>
                    </div>
                  </form>
                </div>


                <div className="w-full">
                  <hr className="border-t border-solid border-gray-300 mt-4 mb-4" />
                </div>


                <div className="w-full flex flex-wrap items-center align-top p-1">

                  <div className="w-full items-center p-1">
                    <h5 className="text-black text-opacity-80 text-2xl">Birthday</h5>
                  </div>

                  <div className="flex h-11 relative w-full min-w-[200px] mt-4">
                    <label
                      className="mr-4 inline-block pl-[0.15rem] hover:cursor-pointer"
                      htmlFor="birthday"
                    >Do you want to hide your age?</label>
                    <input
                      className="mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                      type="checkbox"
                      role="switch"
                      id="birthday"
                      checked={isBirthdayActive}
                      onChange={handleBirthdayChange} />

                  </div>

                  <div className="flex h-11 relative w-full min-w-[200px] mt-4 hidden">
                    <label
                      className="mr-4 inline-block pl-[0.15rem] hover:cursor-pointer"
                      htmlFor="flexSwitchCheckDefault"
                    >Push notifications ?</label>
                    <input
                      className="mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                      type="checkbox"
                      role="switch"
                      id="pushNotification" />

                  </div>
                  <div className="w-full">
                    <hr className="border-t border-solid border-gray-300 mt-4 mb-4" />
                  </div>

                  <div className="w-full items-center p-1">
                    <h5 className="text-black text-opacity-80 text-2xl">Membership</h5>

                    {permissionNames ? (
                      <div className="flex my-2">
                        {(() => {
                          const permissions = permissionNames?.split(',').map(permission => permission.trim());

                          const hasConnectPermission = permissions.includes('user-connect-content') || permissions.includes('c');
                          const hasPremiumPermission = permissions.includes('user-premium-content') || permissions.includes('p');
                          const hasVerifiedPermission = permissions.includes('user-verified-content') || permissions.includes('v');

                          // Check if at least one permission is present
                          const hasAnyPermission = hasConnectPermission || hasPremiumPermission || hasVerifiedPermission;

                          if (hasAnyPermission) {
                            return (
                              <>
                                {hasConnectPermission && (
                                  <img
                                    src={connectM}
                                    alt=""
                                    className="w-22 h-10 mx-1"
                                  />
                                )}

                                {hasPremiumPermission && (
                                  <img
                                    src={premiumM}
                                    alt=""
                                    className="w-22 h-10 mx-1"
                                  />
                                )}

                                {hasVerifiedPermission && (
                                  <img
                                    src={verifiedM}
                                    alt=""
                                    className="w-22 h-10 mx-1"
                                  />
                                )}
                              </>
                            );
                          } else {
                            return (
                              <div className="mt-4">
                                <a href="https://unjected.com/pages/unjected-memberships" target="_blank">
                                  <button className="bg-borderLogin hover:bg-hoverButton rounded-full shadow-md text-white font-bold inline-block py-1 px-12">
                                    BUY MEMBERSHIP
                                  </button>
                                </a>
                              </div>
                            );
                          }
                        })()}
                      </div>
                    ) : (
                      /* Render link if permission_names is null */
                      <div className="mt-4">
                        <a href="https://unjected.com/pages/unjected-memberships" target="_blank">
                          <button className="bg-borderLogin hover:bg-hoverButton rounded-full shadow-md text-white font-bold inline-block py-1 px-12">
                            BUY MEMBERSHIP
                          </button>
                        </a>
                      </div>
                    )}
                  </div>


                </div>


                <div className="w-full">
                  <hr className="border-t border-solid border-gray-300 mt-4 mb-4" />
                </div>


                <div className="w-full flex flex-wrap items-center align-top p-1">

                  <div className="w-full items-center p-1">
                    <h5 className="text-black text-opacity-80 text-2xl">Change account status</h5>
                  </div>

                  <div className="w-full flex">
                    <div className="m-2">
                      <h6 className="text-lg">

                        Account status:

                      </h6>
                    </div>
                    <div className="m-2">
                      <span className="text-sm bg-borderLogin text-white shadow-md rounded-full flex items-center px-3 py-1" >
                        <FaCheckCircle className="mr-2" />
                        {isActive ? 'Deactivated' : 'Active'}
                      </span>
                    </div>
                  </div>

                  <div className="mt-4 text-center">
                    <button type="button"
                      onClick={handleStatusUpdate}
                      className="bg-borderLogin hover:bg-hoverButton rounded-full shadow-md text-white font-bold inline-block py-1 px-12" >
                      {isActive ? 'ACTIVATE' : 'DEACTIVATE'}
                    </button>
                  </div>


                </div>


              </div>


            </div>


          </div>

        </main>
      </div>
      <Footer />

    </>
  )
}