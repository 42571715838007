import Logo from "../assets/images/logo.png"
import React, {useState, useEffect} from "react"
import { 
  FaTwitter,FaInstagram,FaTiktok,FaTelegram
 
 } from "react-icons/fa"

function getYear() {
    return new Date().getFullYear();
}

const Footer = ({isMobile:initialIsMobile}) => {

    const [isMobile, setIsMobile] = useState(initialIsMobile);
    

    const handleResize = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        setIsMobile(width < 768);
    };

    useEffect(() => {
        handleResize(); 
        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    return (
        <div className={`p-4 ${isMobile ? 'flex-col items-center' : 'flex justify-between items-center'} bg-footerColor text-center`}>
            <div className={`mb-4 ${isMobile ? 'flex items-center justify-center' :''}`}>
                <a href="https://unjected.com/" target="_self" title="Unjected">
                    <img alt="" className="h-12 w-40" src={Logo} />
                </a>
            </div> 

            <p className="text-gray-600 font-normal text-sm text-center">
                 Copyright &copy; <a href='https://unjected.com' className="underline">Unjected</a> {getYear()}
            </p>

            

            <div>
                <a className="inline-flex items-center justify-center relative box-border p-2 text-customBlue" href="https://twitter.com/UNJECTED" target="_blank" rel="noopener noreferrer">
                    <FaTwitter className="h-6 w-6" />
                </a>
                <a className="inline-flex items-center justify-center relative box-border p-2 text-customBlue" href="https://www.instagram.com/unjectedofficial/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className="h-6 w-6" />
                </a>
                <a className="inline-flex items-center justify-center relative box-border p-2 text-customBlue" href="https://www.tiktok.com/@unjectedofficial" target="_blank" rel="noopener noreferrer">
                    <FaTiktok className="h-6 w-6" />
                </a>
                <a className="inline-flex items-center justify-center relative box-border p-2 text-customBlue" href="https://t.me/THEUNJECTED" target="_blank" rel="noopener noreferrer">
                    <FaTelegram className="h-6 w-6" />
                </a>
            </div>


            
        </div>
    )
}

export default Footer