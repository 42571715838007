import React, { useState } from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Logo from "../assets/images/logo.png";
import { LuArrowUpRightFromCircle } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from '../components/Snackbar';
const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export default function Verify() {
  const navigate = useNavigate();
  const location = useLocation();
  // const cognitoUserId = location.state && location.state.cognitoUserId;
    const { cognitoUserId, email } = location.state || {};

  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

  const handleSuccessSnackbarClick = () => {
    setShowSuccessSnackbar(true);
  };

  const handleErrorSnackbarClick = () => {
    setShowErrorSnackbar(true);
  };
  const handleVerify = async () => {
    setLoading(true);

    try {
      const response = await axios.post(`${REACT_APP_BASE_API_URL}/verify`, {
        cognito: cognitoUserId,
        code: code,
        email: email
      });

      console.log("respo");
      console.log(response);

      if (response.status === 200) {
        // Verification successful
        //console.log("User Verified:", response.data.data.message);
        // Redirect to success page or handle accordingly
          handleSuccessSnackbarClick(response.data.data.message);
          navigate('/signin');

      } else {
        setShowErrorSnackbar(true);
        setErrorSnackbarMessage(response.data.message || "Verification failed. Please try again.");
      }
    } catch (error) {
      handleErrorSnackbarClick();
      if (error.response && error.response.data && error.response.data.data && error.response.data.data.message) {
        setErrorSnackbarMessage(error.response.data.data.message);
      } else {
        setErrorSnackbarMessage("Something went wrong. Please try again later.");
      }
    }

    setLoading(false);
  };

  return (
    <>
      <Header />
      <div className="bg-hero-pattern bg-cover">
        <main className="container mx-auto px-4 py-11 sm:px-6 lg:p-11 w-full max-w-md rounded">
          <div className="flex flex-col items-center bg-white p-6 rounded-3xl shadow space-y-4">
            <img src={Logo} alt="Logo" className="w-20 h-auto" />

            <h1 className="text-2xl text-black text-opacity-80">Verify</h1>
            <Snackbar
              message="This is a success message!"
              type="success"
              showSnackbar={showSuccessSnackbar}
              setShowSnackbar={setShowSuccessSnackbar}
            />
            <Snackbar
              message={errorSnackbarMessage}
              type="error"
              showSnackbar={showErrorSnackbar}
              setShowSnackbar={setShowErrorSnackbar}
            />
            <form className="mt-2">
              {/* <div className="flex flex-col py-2">
                <div className="rounded-md bg-green-50 flex p-3 text-green-800">
                  <div className="text-green-700 mr-3 p-3 flex text-lg opacity-90">
                    <LuArrowUpRightFromCircle />
                  </div>
                  { <div className="pt-2 text-left overflow-auto">
                    The code has been sent to {email}. Please input the
                    code in order to verify your email address and complete
                    registration.

                  </div> }
                </div>
              </div> */}

              <div className="relative h-10 w-full mt-4">
                <input
                  className="peer w-full rounded-[7px] border border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                  placeholder=" "
                  value={code}
                  name="code"
                  onChange={(e) => setCode(e.target.value)}
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                  Code*
                </label>
              </div>

              <div className="p-3 m-3 text-center">
                <button
                  className="bg-borderLogin hover:bg-customBlue rounded-full shadow-md text-black inline-block py-2 px-12"
                  onClick={handleVerify}
                  disabled={loading}
                >
                  {loading ? "Verifying..." : "VERIFY"}
                </button>
              </div>

              {error && (
                <div className="text-red-500 text-center">{error}</div>
              )}
            </form>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}
