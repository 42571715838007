import React, { useState, useEffect, useRef } from 'react';
import Header from "../components/Header"
import Footer from "../components/Footer"
import { RiInboxUnarchiveLine } from "react-icons/ri";
import { BiSolidMessageAltAdd } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { IoMdSend, IoMdRefreshCircle } from "react-icons/io";
import { useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { ImUser } from "react-icons/im"
const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
import { useUser } from '../contexts/UserContext';
import { FaBars } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import Snackbar from '../components/Snackbar';

const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export default function userChat() {

    const { user } = useUser();
    const id = user ? user.id : null;
    const [userConversationID, setUserConversationID] = useState([]);
    const [loading, setLoading] = useState(false);
    //console.log(user);

    const location = useLocation();

    const AccessToken = user ? user.cognito?.AuthenticationResult?.AccessToken : null;

    useEffect(() => {
        const modalParticipant = location.state?.userData || null;

        if (modalParticipant) {
            axios.post(`${REACT_APP_BASE_API_URL}/messages/find-convo`, {
                sender_user_id: id,
                receiver_user_id: modalParticipant ? modalParticipant.id : null,
                AccessToken: AccessToken
            })
                .then((response) => {
                    // Decrypt the response
                    const decryptedResponse = decryptData(response.data, process.env.REACT_APP_ENCRYPTION_KEY);
                    setUserConversationID(decryptedResponse.data.data);
                    //console.log(decryptedResponse);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);


    const navigate = useNavigate();

    let userParticipant = null;
    let participantUserId = null;
    let starterUserId = null;

    if (location.state && location.state.userData) {
        userParticipant = location.state?.userData || null;
        participantUserId = userParticipant ? userParticipant.id : null;
        starterUserId = id;
    } else {
        userParticipant = location.state?.user || null;


        participantUserId = userParticipant ? userParticipant.participant_user_id : null;
        starterUserId = id;

        if (userParticipant && userParticipant.starter_user_id === id) {
            starterUserId = userParticipant.starter_user_id;
            participantUserId = userParticipant ? userParticipant.participant_user_id : null;

        } else {
            starterUserId = userParticipant.participant_user_id;
            participantUserId = userParticipant.starter_user_id
        }
      //  console.log("participant user from message", userParticipant);
    }


    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

    const scrollableContainerRef = useRef(null);

    const [userData, setUserData] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [messageInput, setMessageInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [selectedTab, setSelectedTab] = useState('Inbox');
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

    const tabs = [
        { tabName: 'Inbox', tabImage: <RiInboxUnarchiveLine size={20} /> },
        { tabName: 'Request', tabImage: <BiSolidMessageAltAdd size={20} /> },
        { tabName: 'Trash', tabImage: <IoTrashOutline size={20} /> },
    ];

    useEffect(() => {
        // Auto-scroll down when new messages are added
        scrollableContainerRef.current.scrollTop = scrollableContainerRef.current.scrollHeight;
    }, [userData, messages]);

    useEffect(() => {

        const fetchInitialData = async () => {

            if (AccessToken) {
                try {
                    const unreadCountsData = {};

                    // Fetch data for each box
                    const boxes = ['main', 'requests', 'deleted'];
                    for (const box of boxes) {
                        const conn = {
                            id: id,
                            box: box,
                            AccessToken: AccessToken
                        };

                        const response = await axios.post(`${REACT_APP_BASE_API_URL}/messages/inbox`, conn);
                        const count = response.data.data.data.reduce((acc, user) => {
                            if (user.participant_unread_count !== null) {
                                return acc + 1;
                            }
                            return acc;
                        }, 0);

                        // Set the unread count for each box
                        unreadCountsData[box] = count;
                    }
                    //console.log(unreadCountsData);

                    // Set the unread counts for all boxes
                    setUnreadCount(unreadCountsData);
                } catch (err) {
                    console.log(err);
                    if (err.response?.data?.data?.message.includes("Auth failed")) {
                        logout();
                        navigate("/signin?sessionExpired=true");
                    }
                }
            }
        };

        fetchInitialData();

    }, [user]);

    useEffect(() => {
        let conversationId = null;
        if (location.state && location.state.userData) {
            conversationId = location.state?.userData ? userConversationID.existingConversationId : null;
          //  console.log("connect me user", conversationId);
        } else {
            conversationId = location.state?.user ? location.state?.user.conversation_id : null;
           // console.log("connection user ", conversationId);
        }


        if (conversationId) {
            const conn = {
                conversation_id: conversationId,
                participant_user_id: id,
                AccessToken: AccessToken
            };

            if (AccessToken) {
                setLoading(true);
                axios.post(`${REACT_APP_BASE_API_URL}/messages/inbox/details`, conn)
                    .then((response) => {
                        const decryptedData = decryptData(response.data, process.env.REACT_APP_ENCRYPTION_KEY);
                        setUserData(decryptedData.data.data);
                        setLoading(false);


                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                        // Show error Snackbar
                        setShowErrorSnackbar(true);
                        // Set the error message
                        setErrorSnackbarMessage(err.message || 'No chats.');
                        if (err.response?.data?.data?.message.includes("Auth failed")) {
                            logout();
                            navigate("/signin?sessionExpired=true");
                        }

                    })
            }
        }
    }, [location.state, userConversationID]);

    const handleSendClick = () => {

        const create = {
            sender_user_id: id,
            receiver_user_id: participantUserId,
            body: messageInput,
            AccessToken: AccessToken
        };

        if (AccessToken) {
            axios.post(`${REACT_APP_BASE_API_URL}/messages/create`, create)
                .then((response) => {
                  //  console.log(response.data);


                    // Check if messages is empty before updating
                    setMessages(prevMessages => {
                        return prevMessages.length === 0 ? [response.data.data.data.us_conversation_message] : [...prevMessages, response.data.data.data.us_conversation_message];
                    });

                })
                .catch((err) => {
                    console.log(err);
                    // Show error Snackbar
                    setShowErrorSnackbar(true);
                    // Set the error message
                    setErrorSnackbarMessage(err.message || 'Message not send.');
                    window.scrollTo(0, 0);
                    if (err.response?.data?.data?.message.includes("Auth failed")) {
                        logout();
                        navigate("/signin?sessionExpired=true");
                    }
                });

            // Clear the message input after sending
            setMessageInput('');
        }
    };

    // Call handleSendClick on key press (Enter)
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents adding a new line in the textarea
            handleSendClick();
        }
    };

    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);
        handleToggleMobileMenu();

        //navigate(`/messages/${tabName}`);
        navigate(`/messages?selectedTab=${tabName}`);
    };


    const handleRefreshChat = () => {

        window.location.reload();
    };

    //href={`/users/${userParticipant.participant_username || userParticipant.username}`}
    const handleClick = (event, username) => {
        event.preventDefault();
        // Navigate to the user page with the userId and username as parameters
        navigate(`/users/${username}`, { state: { userParticipant } });
        //navigate('/userchat', { state: { userData } });
    };

    const handleToggleMobileMenu = () => {
        setMobileMenuVisible(!mobileMenuVisible);
    };


    return (
        <>
            <Header className="fixed top-0 left-0 w-full" />
            <div className="bg-connectionBg bg-cover">
                <main className="container mx-auto px-2 py-11 sm:px-6 lg:p-11 w-full max-w-md lg:max-w-[70%] rounded">
                    <div className="flex flex-col flex-wrap">
                        <div className="ml-4 mt-4">
                            <h5 className="text-black text-opacity-80 text-2xl">Message</h5>
                        </div>
                        <Snackbar
                            message={showSuccessSnackbar.message}
                            type="success"
                            showSnackbar={showSuccessSnackbar}
                            setShowSnackbar={setShowSuccessSnackbar}
                        />
                        <Snackbar
                            message={errorSnackbarMessage}
                            type="error"
                            showSnackbar={showErrorSnackbar}
                            setShowSnackbar={setShowErrorSnackbar}
                        />
                        <br></br>
                        <div className="mb-5">

                            <div className="mt-4 flex flex-col p-6 rounded-2xl shadow-custom">

                                {/* Mobile Menu Button */}
                                <div className="lg:hidden">
                                    <button
                                        onClick={handleToggleMobileMenu}
                                        className="text-black focus:outline-none"
                                    >
                                        <FaBars className="h-5 w-5" />
                                    </button>
                                </div>

                                {/* Mobile Menu */}
                                <div className={`lg:hidden ${mobileMenuVisible ? 'block' : 'hidden'}`}>
                                    <div className="bg-white shadow-custom rounded-md w-[70%] p-2">
                                        {tabs.map((tab, index) => (
                                            <><ul
                                                key={tab.tabName}
                                                className={`hover:border-hoverTab hover:bg-hoverTab group flex-1 transform ${selectedTab === tab.tabName ? 'bg-borderLogin' : ''}`}
                                                onClick={() => {
                                                    handleTabClick(tab.tabName);
                                                    handleToggleMobileMenu();
                                                }}
                                            >
                                                <li className="m-2 -transform bg-transparent px-7 text-center text-borderLogin first-letter:uppercase flex items-center flex-1 justify-start">
                                                    <span
                                                        className={`mr-2 ${selectedTab === tab.tabName ? 'group-hover:text-white text-white' : 'group-hover:text-white'}`}
                                                    >
                                                        {tab.tabImage}
                                                    </span>
                                                    <b className={`block text-md group-hover:text-white ${selectedTab === tab.tabName ? 'text-white' : 'text-borderLogin'} mr-4`}>
                                                        {tab.tabName}
                                                    </b>

                                                    {index === 0 && unreadCount.main > 0 && (
                                                        <div className="bg-borderLogin border border-white p-2 text-white text-xs rounded-full w-3 h-3 flex items-center justify-center">{unreadCount.main}</div>
                                                    )}
                                                    {index === 1 && unreadCount.requests > 0 && (
                                                        <div className="bg-borderLogin border border-white p-2 text-white text-xs rounded-full w-3 h-3 flex items-center justify-center">{unreadCount.requests}</div>
                                                    )}
                                                    {index === 2 && unreadCount.deleted > 0 && (
                                                        <div className="bg-borderLogin border border-white p-2 text-white text-xs rounded-full w-3 h-3 flex items-center justify-center">{unreadCount.deleted}</div>
                                                    )}
                                                </li>
                                            </ul>
                                                {index < tabs.length - 1 && <hr className="border-t border-solid border-white-300" />}

                                            </>
                                        ))}

                                    </div>
                                </div>


                                <div className="mt-4 flex">

                                    <div className="hidden h-20 lg:flex lg:flex-col gap-1 ml-1 mr-1">
                                        {tabs.map((tab, index) => (
                                            <ul
                                                key={tab.tabName}
                                                className={`hover:border-hoverTab hover:bg-hoverTab group flex-1 transform border border-b ${selectedTab === tab.tabName ? 'bg-borderLogin' : ''}`}
                                                onClick={() => handleTabClick(tab.tabName)}
                                            >
                                                <li className="m-4 -transform bg-transparent px-7 text-center text-borderLogin first-letter:uppercase flex items-center flex-1 justify-start">

                                                    <span
                                                        className={`mr-2 ${selectedTab === tab.tabName ? 'group-hover:text-white text-white' : 'group-hover:text-white'}`}
                                                    >
                                                        {tab.tabImage}
                                                    </span>
                                                    <b className={`block text-lg group-hover:text-white ${selectedTab === tab.tabName ? 'text-white' : 'text-borderLogin'} mr-4`}>{tab.tabName}</b>

                                                    {/* Display the badge with count */}

                                                    {index === 0 && unreadCount.main > 0 && (
                                                        <div className="bg-borderLogin border border-white p-3 text-white text-sm rounded-full w-4 h-4 flex items-center justify-center">{unreadCount.main}</div>
                                                    )}
                                                    {index === 1 && unreadCount.requests > 0 && (
                                                        <div className="bg-borderLogin border border-white p-3 text-white text-sm rounded-full w-4 h-4 flex items-center justify-center">{unreadCount.requests}</div>
                                                    )}
                                                    {index === 2 && unreadCount.deleted > 0 && (
                                                        <div className="bg-borderLogin border border-white p-3 text-white text-sm rounded-full w-4 h-4 flex items-center justify-center">{unreadCount.deleted}</div>
                                                    )}

                                                </li>
                                            </ul>
                                        ))}
                                    </div>


                                    <div className="w-full">

                                        <div className="flex items-center ml-2">

                                            {(id == userParticipant.starter_user_id) ? (
                                                <>
                                                    {(userParticipant.participant_user_image_path || userParticipant.image_path) ? (
                                                        <img src={userParticipant.participant_user_image_path
                                                            ? `https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${userParticipant.participant_user_image_path}`
                                                            : `https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${userParticipant.image_path}`}
                                                            alt={''} className="w-10 h-10 rounded-md mr-4" />
                                                    ) : (
                                                        <div className="flex items-center justify-center">
                                                            <ImUser className="w-10 h-10 rounded-md mr-4" />
                                                        </div>
                                                    )}
                                                    <div className="flex items-center justify-between w-full">

                                                        <a href="#" onClick={(event) => handleClick(event, userParticipant.participant_username || userParticipant.username)} className="text-customBlue underline text-xl">{userParticipant.participant_username || userParticipant.username}</a>
                                                        <button
                                                            type="button"
                                                            className="box-content text-borderLogin hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                                            aria-label="refresh"
                                                            onClick={handleRefreshChat}
                                                        >
                                                            <IoMdRefreshCircle className="h-6 w-6" />

                                                        </button>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {(userParticipant.starter_user_image_path || userParticipant.image_path) ? (
                                                        <img src={userParticipant.starter_user_image_path
                                                            ? `https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${userParticipant.starter_user_image_path}`
                                                            : `https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${userParticipant.image_path}`}
                                                            alt={''} className="w-10 h-10 rounded-md mr-4" />
                                                    ) : (
                                                        <div className="flex items-center justify-center">
                                                            <ImUser className="w-10 h-10 rounded-md mr-4" />
                                                        </div>
                                                    )}
                                                    <div className="flex items-center justify-between w-full">
                                                        <a href="#" onClick={(event) => handleClick(event, userParticipant.starter_username || userParticipant.username)} className="text-customBlue underline text-xl">{userParticipant.starter_username || userParticipant.username}</a>
                                                        <button
                                                            type="button"
                                                            className="box-content text-borderLogin hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                                            aria-label="refresh"
                                                            onClick={handleRefreshChat}
                                                        >
                                                            <IoMdRefreshCircle className="h-6 w-6" />

                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className='w-full h-[55vh] overflow-y-scroll rounded-md bg-white m-2 shadow-md' ref={scrollableContainerRef}>
                                            {loading ? (
                                                <div className='py-14 flex justify-center items-center h-full'>
                                                    <div
                                                        className="float-center inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                                        role="status">
                                                        <span
                                                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                                        >Loading...</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    {userData.map((msg, index) => (
                                                        <div key={index}>
                                                            {id == msg.sender_id ? (
                                                                // Sender side
                                                                <div className='flex items-end justify-end p-3'>
                                                                    <div className='flex flex-col items-end max-w-[60%]'>
                                                                        <span className='text-gray-500 text-sm'>{msg.participant_first_name} {msg.participant_last_name}</span>
                                                                        <div className='bg-customBlue text-white rounded-md p-2 w-auto'>
                                                                            {msg.body}
                                                                        </div>
                                                                    </div>
                                                                    {msg.participant_user_image_path ? (
                                                                        <img src={`https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${msg.participant_user_image_path}`} alt='' className='w-8 h-8 rounded-full ml-2' />
                                                                    ) : (
                                                                        <div className="flex items-center justify-center">
                                                                            <ImUser className="w-8 h-8 rounded-full ml-2" />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                // Receiver side
                                                                <div className='flex items-start justify-start p-3'>
                                                                    {msg.participant_user_image_path ? (
                                                                        <img src={`https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${msg.participant_user_image_path}`} alt='' className='w-8 h-8 rounded-full mr-2' />
                                                                    ) : (
                                                                        <div className="flex items-center justify-center">
                                                                            <ImUser className="w-8 h-8 rounded-full mr-2" />
                                                                        </div>
                                                                    )}
                                                                    <div className='flex flex-col max-w-[60%]'>
                                                                        <span className='text-gray-500 text-sm'>{msg.participant_first_name} {msg.participant_last_name}</span>
                                                                        <div className='bg-gray-300 rounded-md p-2 w-auto '>
                                                                            {msg.body}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            {messages.map((msg, index) => (
                                                <div key={index}>
                                                    {id == userParticipant.starter_user_id && user ? (
                                                        <div className='flex items-end justify-end p-3'>
                                                            <div className='flex flex-col items-end max-w-[60%]'>
                                                                <span className='text-gray-500 text-sm'>{userParticipant.starter_first_name || user.first_name} {userParticipant.starter_last_name || user.last_name}</span>
                                                                <div className='bg-customBlue text-white rounded-md p-2 max-w-[70%]'>
                                                                    {msg.body}
                                                                </div>
                                                            </div>
                                                            {userParticipant.starter_user_image_path || user.image_path ? (
                                                                <img src={userParticipant.starter_user_image_path
                                                                    ? `https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${userParticipant.starter_user_image_path}`
                                                                    : `https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${user.image_path}`}
                                                                    alt='' className='w-8 h-8 rounded-full ml-2' />
                                                            ) : (
                                                                <div className="flex items-center justify-center">
                                                                    <ImUser className="w-8 h-8 rounded-full ml-2" />
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className='flex items-end justify-end p-3'>
                                                            <div className='flex flex-col items-end max-w-[60%]'>
                                                                <span className='text-gray-500 text-sm'>{userParticipant.participant_first_name || user.first_name} {userParticipant.participant_last_name || user.last_name}</span>
                                                                <div className='bg-customBlue text-white rounded-md p-2 max-w-[70%]'>
                                                                    {msg.body}
                                                                </div>
                                                            </div>
                                                            {userParticipant.participant_user_image_path || user.image_path ? (
                                                                <img src={userParticipant.participant_user_image_path
                                                                    ? `https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${userParticipant.participant_user_image_path}`
                                                                    : `https://unjected-app-prod-rmch5hdrnor.s3.amazonaws.com/${user.image_path}`}
                                                                    alt='' className='w-8 h-8 rounded-full ml-2' />
                                                            ) : (
                                                                <div className="flex items-center justify-center">
                                                                    <ImUser className="w-8 h-8 rounded-full ml-2" />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}

                                        </div>

                                        <div className='flex h-20 '>

                                            <textarea rows="3"
                                                placeholder="Type a message..."
                                                value={messageInput}
                                                onChange={(e) => setMessageInput(e.target.value)}
                                                onKeyDown={handleKeyDown}
                                                className="appearance-none resize-none block w-full h-full bg-transparent text-gray-900 border border-gray-400 rounded-xl py-3 px-4 m-2 leading-tight focus:outline-none focus:bg-transparent focus:border-customBlue focus:border-3">
                                            </textarea>
                                            <button
                                                onClick={handleSendClick}
                                                className="flex m-2 bg-transparent items-center text-customBlue border border-customBlue rounded h-full w-auto hover:bg-blue-50">
                                                <span className='m-2 font-bold'>SEND</span>
                                                <IoMdSend className="m-2 h-6 w-6" />
                                            </button>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </>
    )
}
