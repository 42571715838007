import React, { useState } from "react";
import Logo from "../assets/images/logo.png";
import { useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { validateFields } from "../components/valid";
import axios from "axios";
import Snackbar from '../components/Snackbar';
const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const CryptoJS = require("crypto-js");


function decryptData(ciphertext, key) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, key);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}
export default function Signup() {
  const navigate = useNavigate();
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

  const handleSuccessSnackbarClick = () => {
    setShowSuccessSnackbar(true);
  };

  const handleErrorSnackbarClick = () => {
    setShowErrorSnackbar(true);
  };
  const redirectToVerify = (cognitoUserId, email) => {
    // Pass the cognitoUserId to the verify page

    navigate('/verify', { state: { cognitoUserId, email } });
  };

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    password: ''
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateFields(formData);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);

      try {
        // Make API call
        const response = await axios.post(`${REACT_APP_BASE_API_URL}/users/register`, formData);

        // Check the response status and handle accordingly
        if (response.status === 200) {
          // Decrypt response data
          const decryptedResponse = decryptData(response.data, process.env.REACT_APP_ENCRYPTION_KEY);
        
          // Extract cognito_user_id from the decrypted response
          const cognitoUserId = decryptedResponse.data.data.cognito_user_id;
          const email = decryptedResponse.data.data.email;

          // Redirect to the verify page and pass cognitoUserId as state
          redirectToVerify(cognitoUserId, email);
        } else {
          // Handle other status codes
          const decryptedErrorMessage = decryptData(response.data.message, process.env.REACT_APP_ENCRYPTION_KEY);

          // Log the decrypted error message
          console.error("Registration failed:", decryptedErrorMessage);
    
          // Set the decrypted API error message in the Snackbar
          setShowErrorSnackbar(true);
          setErrorSnackbarMessage(decryptedErrorMessage || "Registration failed. Please try again.");
        }
      } catch (error) {
        // Handle API call error
        console.error("API call error:", error);

        // Set the API error message in the Snackbar
        setShowErrorSnackbar(true);

        if (error.response && error.response.data && error.response.data ) {
          const decryptedErrorMessage = decryptData(error.response.data, process.env.REACT_APP_ENCRYPTION_KEY);
          setErrorSnackbarMessage(decryptedErrorMessage.data.message);
        } else {
          setErrorSnackbarMessage("Something went wrong. Please try again later.");
        }
      }

      setLoading(false);;
    }
  };
  return (
    <>
      <Header />

      <div className="bg-hero-pattern bg-cover">
        <main className="container mx-auto px-4 py-11 sm:px-6 lg:p-11 w-full max-w-md rounded">
          <div className="flex flex-col items-center bg-white p-6 rounded-3xl shadow space-y-4">
            <img src={Logo} alt="Logo" className="w-20 h-auto" />

            <h1 className="text-2xl text-black text-opacity-80 ">Sign up</h1>
            <Snackbar
              message="This is a success message!"
              type="success"
              showSnackbar={showSuccessSnackbar}
              setShowSnackbar={setShowSuccessSnackbar}
            />
            <Snackbar
              message={errorSnackbarMessage}
              type="error"
              showSnackbar={showErrorSnackbar}
              setShowSnackbar={setShowErrorSnackbar}
            />
            <form className="mt-2" onSubmit={handleSubmit}>
              <div className="flex">
                <div className="relative flex flex-col max-w-[50%] mt-4 mr-2">
                  <input
                    name="first_name"
                    className="peer w-full rounded-[7px] border border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                    placeholder=" "
                    autoComplete="given-name"
                    onChange={handleChange}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                    First Name*
                  </label>
                  {errors.first_name && <span className="py-1 text-red-500 text-xs italic">{errors.first_name}</span>}
                </div>

                <div className="relative flex flex-col max-w-[50%] mt-4 ml-2">
                  <input
                    name="last_name"
                    className="peer w-full rounded-[7px] border border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                    placeholder=" "
                    autoComplete="family-name"
                    onChange={handleChange}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                    Last Name*
                  </label>
                  {errors.last_name && <span className="py-1 text-red-500 text-xs italic">{errors.last_name}</span>}
                </div>

              </div>

              <div className="relative w-full min-w-[200px] mt-4">
                <input
                  name="username"
                  className="peer w-full rounded-[7px] border border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                  placeholder=" "
                  autoComplete="username"
                  onChange={handleChange}
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                  Username*
                </label>
                {errors.username && <span className="py-1 text-red-500 text-xs italic">{errors.username}</span>}
              </div>

              <div className="relative w-full min-w-[200px] mt-4">
                <input
                  name="email"
                  className="peer w-full rounded-[7px] border border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                  placeholder=" "
                  type="email"
                  autoComplete="email"
                  onChange={handleChange}
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                  Email Address*
                </label>
                {errors.email && <span className="py-1 text-red-500 text-xs italic">{errors.email}</span>}
              </div>

              <div className="relative w-full min-w-[200px] mt-4">
                <input
                  name="password"
                  className="peer w-full rounded-[7px] border border-inputBorder bg-transparent px-3 py-2.5 text-sm font-normal text-placeHolderText outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-inputBorder placeholder-shown:border-t-inputBorder focus:border-3 focus:border-customBlue focus:border-t-transparent focus:outline-0"
                  placeholder=" "
                  type="password"
                  autoComplete="new-password"
                  onChange={handleChange}
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex w-full select-none text-[11px] leading-tight text-placeHolderText transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-inputBorder before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-inputBorder after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-200 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-customBlue peer-focus:before:border-t-2 peer-focus:before:border-l-3 peer-focus:before:border-customBlue peer-focus:after:border-t-3 peer-focus:after:border-r-3 peer-focus:after:border-customBlue">
                  Password*
                </label>
                {errors.password && <span className="py-1 text-red-500 text-xs italic">{errors.password}</span>}
              </div>
              <div className="pt-7 pb-4 text-center">
                <button
                  type="submit"
                  className={`bg-borderLogin rounded-full shadow-md text-white font-bold inline-block py-1 px-12 ${loading ? "opacity-50" : ""}`}
                  disabled={loading}
                >
                  {loading ? "Signing Up..." : "SIGN UP"}
                </button>
              </div>

              <div className="text-center">
                <span className="text-gray-600 text-sm">
                  Already have an account?{" "}
                </span>
                <a href="/Signin" className="text-customBlue underline hover:underline-dark">
                  Log in
                </a>
              </div>
            </form>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );

}